<template>
    <div>
        <formulario-envios />
    </div>
</template>

<script>
/* eslint-disable */
import FormularioEnvios from "./FormularioEnvios.vue";

export default {
    name: "sendMessage-default",
    components: {
        FormularioEnvios
    },
    data() {
        return {
        }
    },
    activated() {
        //if (window.performance && window.performance.navigation.type === window.performance.navigation.TYPE_RELOAD) {
            console.log('Ingresa a recargar');
            //window.location.reload(true);
        //}
    },


    methods: {


    },
    beforeUnmount() {

    },
    mounted() {
        
    }
}
</script>