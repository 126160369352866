<!-- eslint-disable vue/comment-directive -->
<template>
    <div class="container-fluid">
        <div class="row" style="width: 100%;margin:0px">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px">
                <nav class="navbar navbar-expand-lg navbar-dark bg-dark"
                    style="height: 250px;display:block;    margin: 0px !important">

                    <div style=" justify-content: space-between;display: flex;">
                        <router-link to="/developers">
                            <img class="imagen img-fluid"
                                src="https://www.whatsivo.com/wp-content/uploads/2021/06/logo-whatsivoRecurso-1.png"
                                alt="Whatsivo Logo" />
                        </router-link>
                    </div>

                    <!--                     <p
                        style="text-align: center; color:white; font-weight: bold;font-size:23px; margin-bottom: 0px;margin-top: 30px;    line-height: 28px;">
                        Sólo un paso más... ¡Actualiza tu contraseña!</p> -->

                    <div class="row justify-content-center mt-6">
                        <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">


                            <div class="card text-center">
                                <div class="card-header">
                                    <a class="card-link" style=" margin-right: 5px; ">API DE WHATSAPP</a>
                                    <i class="fab fa-whatsapp"></i>
                                </div>
                                <div class="card-body">
                                    <h5><a>ENVÍOS DE MENSAJES</a></h5>
                                    <router-link to="/developers/api/envios">
                                        <button type="button" class="btn btn-primary btn-md mx-1 mt-2"><i class=""
                                                style="margin-right: 5px;"></i>Ir al servicio</button>
                                    </router-link>
                                    <a href="https://www.whatsivo.com/wp-content/uploads/2023/10/Documentacion-Api-WhatSivo.pdf">
                                        <!-- <button class="btn btn-light mt-3" style="margin: 10px">Documentación</button> -->
                                        <button type="button" class="btn btn-primary btn-md mx-1 mt-2"><i class=""
                                                style="margin-right: 5px;"></i>Documentación</button>
                                    </a>
                                </div>
                            </div>


                        </div>

                         <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 m-top">

                            <div class="card text-center">
                                <div class="card-header">
                                    <a class="card-link" style=" margin-right: 5px; ">CONSULTA DE DATOS ECUADOR</a>
                                    <i class="fa fa-search"></i>
                                </div>
                                <div class="card-body">
                                    <h5><a>DNI y RUC</a></h5>
                                    <router-link to="/developers/api/consultas">                                        
                                        <button type="button" class="btn btn-primary btn-md mx-1 mt-2"><i class=""
                                                style="margin-right: 5px;"></i>Ir al servicio</button>
                                    </router-link>
                                    <a href="">
                                        <button type="button" class="btn btn-primary btn-md mx-1 mt-2"><i class=""
                                                style="margin-right: 5px;"></i>Documentación</button>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>

                </nav>

            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */


export default {
    name: "dashboardDev-default",
    components: {

    },
    data() {
        return {
        }
    },

    methods: {

    },

    mounted() {

    }
}
</script>

<style scoped>
.container-fluid {
    padding: 0px;
    margin: 0px;
}

.bg-dark {
    background: linear-gradient(87deg, #5e02ca, #8100d3) !important;
    height: 160px;
}


.card-header {
    background-color: #f0f2f5;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Para centrar horizontalmente el contenido */
}

.imagen {
    width: 200px;
    /* Adjust the width as per your requirements */
    height: auto;
    /* Maintain aspect ratio */
}

.bg-dark {
    background: linear-gradient(87deg, #f7931e, #f7931e) !important;
    height: 160px !important;
}

@media screen and (max-width: 769px) {
    .m-top {
        margin-top: 2rem;
    }

    .imagen {
        width: 30%;
        height: auto;
        /* Maintain aspect ratio */
    }

    .bg-dark {
        height: 140px !important;
    }


}
</style>