<template>
    <div class="card" style="margin-bottom:10px">

        <div class=" card-body">

            <div class="row">

                <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12"
                    style="margin-bottom:10px;  justify-content: space-between;display: flex;">

                    <h5 class="card-title mb-0 text-uppercase font-weight-bold" style="font-size: 12px;"
                        :style="buttonStyle"> {{ textButtonQR }}
                    </h5>
                    <!-- <i v-if="!loading" @click="refresh" style="cursor:pointer;font-size: 6px"
                        class="fas fa-sync text-sm opacity-10"></i> -->
                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12">

                    <div v-show="ocultarIniciarSession">
                        <div id="app" align="center">
                            <v-lazy-image :src='spiner.img' alt="QR Code" id="spiner" style="display: none" />
                        </div>

                        <div id="app" align="center">
                            <v-lazy-image style="width: 200px; display: none" alt="QR Code" id="qrcode" />
                        </div>
                    </div>

                    <div class="logs" align="center" v-show="ocultarIniciarSession">
                        <ul>
                            <li v-for="log in logs" :key="log.id">{{ log.message }}</li>
                        </ul>
                    </div>

                </div>
            </div>

            <div class="row" v-show="ocultarIniciarSession">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12"
                    style="margin-top:10px; display:flex; justify-content:center;">
                    <Skeletor v-if="loading" :shimmer="true" height="38" width="100%" />
                    <button v-else id="conexion" v-on:click="conexion()" class="btn btn-primary btn-sm"
                        style="width: 300px; margin: auto;">
                        <i class="conexion"> Crear Sesion</i>
                    </button>
                </div>
            </div>



        </div>
    </div>
</template>
<script>

/* eslint-disable */
//import $ from 'jquery';


export default {
    name: "qr-default",
    components: {





    },

    data() {


        return {
            logs: [],
            userDemo: '',
            loading: true,
            ocultarIniciarSession: true,
            textButtonQR: 'SCAN QR',
            style: { color: 'black', background: 'white' },
            spiner: {
                value: document.querySelector('#spinner'),
                img: 'https://www.whatsivo.com/wp-content/uploads/2022/06/spinner.gif',
            },
            user: {
                sender: localStorage.getItem("sender"),
                usuario: JSON.parse(localStorage.getItem('userData')).user
            }

        }
    },
    computed: {
        buttonStyle() {
            return this.style
        }
    },
    watch: {

    },

    activated() {


        setTimeout(() => {
            this.loading = false
        }, 5000);

        //console.log('carga 2 componente');

        const sesionIniciada = localStorage.getItem("loggedIn");

        switch (sesionIniciada) {
            case "NO":
                this.ocultarIniciarSession = true;
                break;
            case "QR":
                this.ocultarIniciarSession = false;
                this.textButtonQR = "HA OCURRIDO UN ERROR EN LA SESIÓN VINCULADA";
                this.style.color = 'white'
                this.style.background = 'red'
                this.showErrorToast('Vuelva a ingresar al sistema')
                break;
            case "YES":
                this.ocultarIniciarSession = false;
                this.textButtonQR = "MÓVIL VINCULADO";
                break;

            default:
                break;
        }

        this.$eventBus.on('showCargaQR', () => {
            console.log('recibiendo evento');
        });

    },

    create() {


    },

    mounted() {

        let userData = JSON.parse(localStorage.getItem("userData"));
        this.userDemo = userData.user

        if (this.userDemo == 'demo@whatsivo.com') {
            localStorage.setItem("loggedIn", "YES");
            localStorage.setItem("sender", "administrador");
        }





    },

    methods: {

        prueba() {

            console.log('Biiiien')

        },

        showSuccessToast(message) {
            this.$toast.success(message, {
                position: "bottom-right",
                max: 10
            });
        },

        showErrorToast(message) {
            this.$toast.error(message, {
                position: "bottom-right",
                max: 10
            });
        },

        conexion() {
            this.$swal({
                title: "¿Seguro que deseas conectar tu móvil?",
                icon: 'info',//'question',
                width: '400px',
                showCancelButton: true,
                confirmButtonText: 'Crear sesión',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    //Esta propiedad te permite definir una función que se ejecuta alguna acción antes de confirmar, si es necesario
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            }).then((result) => {
                if (result.isConfirmed) {

                    this.conexion2()

                }
            });
        },

        async conexion2() {

            if (this.userDemo == 'demo@whatsivo.com') {
                localStorage.setItem("loggedIn", "YES");
                localStorage.setItem("sender", "administrador");
                return this.showErrorToast('Su cuenta es Demo y no puede vincular')
            }

            this.textButtonQR = "Scan Código Qr";
            const socket = io("https://app-whatsivo.com:8000");
            //const socket = io("http://localhost:8000/");
            localStorage.setItem("loggedIn", "QR");
            const sender = localStorage.getItem("sender");
            const descripcion = this.user.usuario;
            document.getElementById("spiner").style.display = "block";
            document.getElementById("qrcode").style.display = "none";
            document.getElementById("conexion").disabled = true;
            document.getElementById("conexion").innerHTML =
                '<i style="margin-right:10px; color: #17c000" class="fas fa-circle-notch fa-spin"></i>Generando código QR, espere..';


            socket.on('message', function (data) {
                console.log(data);
            });

            socket.emit('recibido', 'OK desde VueJs');


            this.$https.post('/api/auth/iniciaSesion', { sender: sender, descripcion: descripcion });

            socket.on("message", () => {

                this.addLog("Generando código QR...");

                $("#conexion").html('<i style="margin-right:10px; color: #17c000" class="fas fa-circle-notch fa-spin"></i>Esperando vinculación con el sistema...');

            });

            socket.on("qr", (data) => {
                document.getElementById("spiner").style.display = "none"; //Oculto git
                document.getElementById("qrcode").style.display = "block"; //muestro qr
                $("#qrcode").attr("src", data.src);
            });

            socket.on("ready", () => {
                console.log('Ingresa al ready');

                this.ocultarIniciarSession = false
                let loggedIn = localStorage.getItem("loggedIn");

                if (loggedIn === "QR") {
                    this.compruebaAcceso();
                }

            });

            socket.on("authenticated", () => {

                //console.log('ingresa a authenticated');
                //console.log(data);

                this.addLog("Whatsapp is authenticated!");
                this.textButtonQR = 'MÓVIL VINCULADO'
                let loggedIn = localStorage.getItem("loggedIn");

                socket.disconnect();

                if (loggedIn === "QR") {
                    this.compruebaAcceso();
                }

                this.ocultarIniciarSession = false

            });


        },

        addLog(message) {
            const existingLog = this.logs.find((log) => log.message === message);
            if (existingLog) {
                existingLog.timestamp = Date.now();
            } else {
                this.logs.push({
                    id: Date.now(),
                    message: message,
                    timestamp: Date.now(),
                });
            }
        },

        async compruebaAcceso() {
            console.log('Ingresa a comprobar acceso');

            const swal = this.$swal.fire({
                html: "<span style='font-size:14px'>Vinculando al sistema, por favor espere.. </span>",
                position: "center",
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                    this.$swal.showLoading();
                }
            }).then(
                () => { },
                (dismiss) => {
                    if (dismiss === 'timer') {
                        console.log('closed by timer!!!!');
                        this.$swal.fire({
                            title: 'Finished!',
                            icon: 'success',
                            showConfirmButton: false
                        })
                    }
                })


            console.log('esperando 1');
            await this.$sleepTimer.generateTiempo(8000)
            //await this.$sleepTimer.hola(5000)
            console.log('Ya se ejecuto');


            try {
                const datos_envio = {
                    //sender: localStorage.getItem("sender"),
                    sender: localStorage.getItem("sender"),
                    message: `Comprobación de cuentas autorizadas *${this.user.usuario}*`,
                    number: '593985067512',
                };

                //console.log(datos_envio);

                const { data } = await this.$https.post('api/auth/compruebaLinea', datos_envio);
                const sendMovil = data.response.from.slice(0, -5);

                console.log('El movil comprobado es: ' + sendMovil);

                const autorizado = await this.comprobarAutorizacion(sendMovil);

                console.log('autorizado');
                console.log(autorizado)

                if (autorizado) {
                    //console.log('Bienvenida a la plataforma');
                    localStorage.setItem("loggedIn", "YES");

                    this.$swal.fire({
                        icon: "info",
                        title: "<strong>Control de Acceso<strong>",
                        text: "Bienvenido, a nuestra plataforma!",
                        confirmButtonText: "Aceptar",
                        customClass: {
                            confirmButton: "updateColor"
                        },
                        transition: "zoom",
                    });

                } else {
                    console.log('Linea Sin permiso');

                    //aqui debo borrar del localstorade y hacer reload
                    localStorage.removeItem("userData");
                    localStorage.removeItem("session");
                    localStorage.removeItem("loggedIn");
                    localStorage.removeItem("sender");

                    return this.$swal.fire({
                        icon: "error",
                        title: "<strong>Control de Acceso<strong>",
                        text: "Línea de celular no tiene acceso",
                        confirmButtonText: "Aceptar",
                        transition: "zoom",
                    });

                }
            } catch (error) {
                swal.close();
                console.log(error);
                this.showErrorToast(error.message);
            }
        },

        async comprobarAutorizacion(movil) {
            try {
                console.log('Ingresa a comprobarAutorizacion');
                console.log(movil);
                const tiene_limite = await this.lineaLimite();

                if (tiene_limite) {
                    return true;
                }
                const { data } = await this.$https.post('api/auth/compruebaSession', { movilSesionIniciada: movil });
                console.log(data);

                console.log('--------------');

                if (data.code == 200) {

                    localStorage.setItem("loggedIn", "YES");
                    return true;

                } else {

                    console.log('Ocurrio un error');
                    return false;

                }
            } catch (error) {
                console.log('Error:');
                console.log(error);
                this.showErrorToast(error.message);
                return false;
            }
        },

        async lineaLimite() {
            try {
                const response = await this.$https.post('api/auth/getLimite', { tokenAuth: this.user.usuario });
                console.log('sinLimite');
                console.log(response.data);
                return response.data.status;
            } catch (error) {
                console.log('Error:');
                console.log(error);
                this.showErrorToast(error.message);
                return false;
            }
        },

        cargaPrueba() {
            console.log('cargaPrueba');
        }



    }

}
</script>

<style scoped>
/* Con esto elimino el scroll horizontal del alert con el loading */
.swal2-popup {
    display: inline-table !important;
}

.swal2-popup.swal2-modal.swal2-loading.swal2-show {
    display: inline-table !important;
}


.swal2-container {
    overflow-x: hidden !important;
    white-space: nowrap !important;
}

.swal2-container {
    overflow-x: hidden;
    white-space: nowrap;
}


.my-swal-popup-class {
    background-color: white;
}

.updateColor {
    background-color: #00b63e;
    color: #fff;
}
</style>