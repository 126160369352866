<template>
    <div class="card" style="margin-bottom: 10px">
        <div class="card-body">
            <div class="row">
                <div class="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9">
                    <p style="margin: 10px; font-weight: bold;">
                        <i style="margin-right: 10px;" class="fas fa-list"></i>
                        LISTADO DE MENSAJES ENVIADOS
                    </p>
                </div>

                <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3" align="right">
                    <i @click="refresh" style="cursor:pointer; margin-left: auto;" class="fas fa-sync text-sm opacity-10">
                        Recargar
                    </i>
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px">
                <div class="row justify-content-center">

                    <div class="col-6 col-sm-10 col-md-6 col-lg-6 col-xl-6" style="display: flex; margin-right: -10px;">
                        <input placeholder="Ingrese su búsqueda" class="form-control col-lg-12" autocomplete="off"
                            style="margin-right: 5px; margin-bottom: 0;" />
                    </div>


                    <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1" style="display: flex">

                        <i style="cursor:pointer; margin:auto" class="fas fa-search text-sm opacity-10"></i>
                    </div>



                </div>
            </div>



            <DataTable :columns="columns" ref="myDataTable" :options="options" class="table tablesorter mt-3" width="100%">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Fecha/Hora</th>
                        <th data-priority="1">Nombres</th>
                        <th>Mensaje</th>
                        <th>Campaña</th>
                        <th>Tipo</th>
                        <th>Celular</th>
                        <th>Estado</th>
                    </tr>
                </thead>
            </DataTable>
        </div>


    </div>
</template>

<script>
/*eslint-disable */
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';
import 'datatables.net-responsive';
//import $ from 'jquery';

DataTable.use(DataTablesCore);

export default {
    name: 'pruebas',
    components: {
        DataTable,
    },
    data() {

        return {
            swalInstance: null,
            loading: true,
            dataTableInstance: null,
            transactions: [],
            tokenAuth: JSON.parse(localStorage.getItem('userData')).user,
            columns: [
                {
                    data: 'index', // Utiliza la propiedad "index" del backend
                },

                {
                    data: 'fecha',
                    render: function (data, type) {
                        if (type === 'display' || type === 'filter') {
                            const fechaHora = new Date(data);
                            const fechaFormateada = fechaHora.toLocaleDateString('es-ES', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit'
                            });
                            const horaFormateada = fechaHora.toLocaleTimeString('es-ES', {
                                hour12: false,
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                            });
                            return `${fechaFormateada} ${horaFormateada}`;
                        }
                        return data;
                    }
                },

                { data: 'clientes' },
                { data: 'mensaje' },
                { data: 'campana' },
                { data: 'tipo' },
                { data: 'movil' },
                { data: 'estado' },
            ],
            options: {
                searching: false, //Cuadro de busqueda
                responsive: true,
                ordering: false,                
                lengthChange: false, //Filtro
                lengthMenu: [
                    [5, 15, 25, 35, 50, -1],
                    [5, 15, 25, 35, 50, 'All'],
                ],
                pageLength: 5,
                "language": {
                    "search": "Buscar:",
                    "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                    "searchPlaceholder": "Ingrese algún dato",
                    "zeroRecords": "Lo sentimos, no se encontraron resultados",
                    "processing": "Buscando. Espere por favor...",
                    "sLengthMenu": "Mostrar _MENU_ registros",
                    "oPaginate": {
                        "sFirst": "Primero",
                        "sLast": "Último",
                        "sNext": "Siguiente",
                        "sPrevious": "Anterior"
                    },

                },
                serverSide: true,
                ajax: {
                    url: 'http://localhost:3000/api/auth/reportePruebas',
                    type: 'POST',
                    contentType: 'application/json',
                    data: params => {
                        this.loading = true;
                        this.pruebas(true)
                        return JSON.stringify({
                            tokenAuth: this.tokenAuth,
                            page: (params.start / params.length) + 1,
                        });
                    },
                    dataSrc: 'data',
                    complete: () => {
                        this.pruebas(false)
                        this.loading = false; // Desactiva el indicador de carga después de completar la solicitud AJAX
                    }
                },


            },
        };
    },
    mounted() {
        console.log(this.tokenAuth);

    },

    created() {

    },
    watch: {

    },
    methods: {

        pruebas(bandera) {

            if (bandera) {

                this.$swal.fire({
                    html: "<span style='font-size:14px'>Cargando datos, por favor espere.. </span>",
                    position: "center",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    didOpen: () => {
                        this.$swal.showLoading();
                    }
                });

            } else {
                window.scrollTo(0, 0) // Mover el scroll lateral hacia arriba
                this.$swal.close();

            }
        },

        async refresh() {


            try {
                const response = await fetch('http://localhost:3000/api/auth/reportePruebas', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        tokenAuth: this.tokenAuth,
                        page: 1, // Para recargar la primera página
                    }),
                });

                const data = await response.json();
                this.transactions = data.data; // Actualiza los datos en la propiedad

                let dataTableInstance = this.$refs.myDataTable.dt
                dataTableInstance.clear(); // Limpia la tabla
                dataTableInstance.rows.add(this.transactions); // Agrega los nuevos datos
                dataTableInstance.draw(); // Redibuja la tabla



            } catch (error) {
                console.error('Error al recargar los datos:', error);
            } finally {
                this.loading = false; // Ocultar el indicador de carga
            }
        }


    }
};
</script>

<style lang="scss">
@import 'datatables.net-dt';
@import 'datatables.net-responsive-dt';
</style>
