<template>
    <div class="row" v-if="loading2">

        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" align="center"
            style="min-height: calc(var(--vh, 1vh) * 80);display: flex;">
            <div style="margin: auto;text-align: center;">
                <i style="font-size: 50px" class="fas fa-spinner fa-pulse"></i>


                <p style="font-size: 16px; font-weight: bold;">
                    Comprobando permisos
                </p>

            </div>

        </div>
    </div>

    <div class="card" style="margin-bottom:10px" v-show="ocultar">

        <div class=" card-body">

            <div class="row">

                <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">

                    <p style="margin:10px; font-weight: bold;"><i style="margin-right: 10px;" class="fas fa-list"></i>
                        LISTADO DE REGISTRADOS</p>

                </div>


                <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1" align="center" style="display:flex">
                    <Skeletor v-if="loading" :shimmer="true" height="20" width="100%" style="margin:auto" />
                    <i v-else @click="getLista()" style="cursor:pointer; margin:auto"
                        class="fas fa-sync text-sm opacity-10"></i>
                </div>

            </div>

            <div v-if="loading" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px">

                <div class="row">

                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                        <Skeletor :shimmer="true" height="40" width="50%" />

                    </div>

                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                        <Skeletor :shimmer="true" height="10" pill width="30%" style="margin-bottom: 10px;" />
                        <Skeletor :shimmer="true" height="40" width="100%" />

                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <table class="table tablesorter">
                            <thead class="thead-light">
                                <tr>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>

                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>


                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>





                                </tr>
                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>





                                </tr>
                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>





                                </tr>

                            </tbody>
                        </table>

                    </div>

                    <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                        <Skeletor :shimmer="true" height="10" pill width="80%" />
                    </div>

                    <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">

                        <div class="row">
                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <Skeletor :shimmer="true" height="10" width="100%" />
                            </div>

                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" align="center">
                                <Skeletor :shimmer="true" circle size="30" />
                            </div>

                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <Skeletor :shimmer="true" height="10" width="100%" />
                            </div>


                        </div>


                    </div>

                </div>

            </div>

            <DataTable v-else :columns="columns" :options="options" :data="transactions" class="table tablesorter"
                width="100%">
                <thead>
                    <tr>
                        <th style="width: 8%;">#</th>
                        <th data-priority="1">Fecha/Hora</th>
                        <th>Cédula</th>
                        <th data-priority="2">Nombres</th>
                        <th>Móvil</th>
                        <th>F. Nac</th>
                        <th>Domicilio</th>
                        <th>Calle</th>
                        <th>Acción</th>
                    </tr>
                </thead>





            </DataTable>



            <div class="row">
                <div class="col-12 col-md-6 centra-botones">
                    <div class="text-md-left">
                        <button type="button" class="btn btn-primary" id="print" @click="Imprimir"
                            style="margin-right: 5px;">
                            <i class="fas fa-print"></i> Imprimir
                        </button>
                        <button type="button" class="btn btn-primary" id="xlxs" @click="Imprimir"
                            style="margin-right: 5px;">
                            <i class="fas fa-download"></i> Generar XLXS
                        </button>
                    </div>
                </div>
            </div>






        </div>



    </div>
</template>
<script>
/*eslint-disable */
import { JSEncrypt } from 'jsencrypt'
import keys from '../../store/keys';

import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';
import 'datatables.net-responsive';
import $ from 'jquery';

import Buttons from 'datatables.net-buttons-bs5';
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5';
import print from 'datatables.net-buttons/js/buttons.print';

import pdfmake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import JsZip from 'jszip';
window.JSZip = JsZip;

DataTable.use(DataTablesCore);
DataTable.use(ButtonsHtml5);
DataTable.use(print);

pdfmake.vfs = pdfFonts.pdfMake.vfs;


export default {
    components: {
        DataTable
    },
    data() {

        return {
            rol: null,
            loading: true,
            loading2: true,
            ocultar: false,
            transactions: [],

            columns: [
                {
                    data: null,
                    render: function (data, type, row, meta) {
                        const index = meta.row + 1;
                        return index;
                    }
                },

                {
                    data: 'fech_creacion',

                    render: function (data, type, row, meta) {
                        if (type === 'display' || type === 'filter') {
                            const fechaHora = new Date(data);
                            const fechaFormateada = fechaHora.toLocaleDateString('es-ES', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit'
                            });
                            const horaFormateada = fechaHora.toLocaleTimeString('es-ES', {
                                hour12: false,
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                            });
                            return `${fechaFormateada} ${horaFormateada}`;
                        }
                        return data;
                    }

                },
                { data: 'cedula' },
                { data: 'nombres' },
                { data: 'movil' },
                { data: 'fecha_nacimiento' },
                { data: 'domicilio' },
                { data: 'calles_domicilio' },
                {
                    data: null,
                    render: function (data, type, row, meta) {
                        return `<button data-id="${data.id}" class="consultar btn btn-primary btn-sm" type="button" style="width:100%; background: #00b63e!important;">Eliminar</button>`;
                    }
                },
            ],
            options: {
                responsive: true,
                dom: 'lBfrtip',
                "ordering": false,
                columnDefs: [
                    { responsivePriority: 1, targets: 0 },
                    { responsivePriority: 2, targets: -1 }
                ],
                lengthMenu: [
                    [7, 15, 25, 35, 50, -1],
                    [7, 15, 25, 35, 50, "All"],
                ],
                pageLength: 15,
                "language": {
                    "search": "Buscar:",
                    "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                    "searchPlaceholder": "Ingrese algún dato",
                    "zeroRecords": "Lo sentimos, no se encontraron resultados",
                    "processing": "Buscando. Espere por favor...",
                    "sLengthMenu": "Mostrar _MENU_ registros",
                    "oPaginate": {
                        "sFirst": "Primero",
                        "sLast": "Último",
                        "sNext": "Siguiente",
                        "sPrevious": "Anterior"
                    },
                },

                buttons: [
                    {
                        title: 'Reporte de Registrados',
                        extend: 'excelHtml5',
                        text: '<i class="fa fa-file-excel-o"></i> Excel',
                        className: 'btn btn-success btn-spaced'                       
                    },
                    {
                        title: 'Reporte de productos',
                        extend: 'pdfHtml5',
                        text: '<i class="fa fa-file-pdf"></i> PDF',
                        className: 'btn btn-danger btn-spaced'
                    },
                    {
                        title: '<div style="text-align: center;">Reporte de Registrados</div>',
                        extend: 'print',
                        text: '<i class="fa fa-print"></i> IMPRIMIR',
                        className: 'btn btn-dark btn-spaced'
                    },
                    {
                        title: 'Reporte de productos',
                        extend: 'copy',
                        text: '<i class="fa fa-copy"></i> COPIAR',
                        className: 'btn btn-ligth btn-spaced'
                    }

                ]

            }
        }
    },
    watch: {



    },
    methods: {

        cargaData() {


        },

        async getLista() {
            this.loading = true

            let sendDatos = {
                tokenAuth: JSON.parse(localStorage.getItem("userData")).ruc,
                rol: this.rol
            }
            const response = await this.$https.post('api/auth/getSeachFormulario', sendDatos);
            const data = response.data;

            console.log(data);
            this.transactions = []

            if (data.code === 200) {

                this.loading = false

                this.transactions = data.datos

                //console.log('Carga Nuevos push');
                //console.log(this.jsonTransacciones);

            } else {
                this.loading = false
            }
        },

        descifra() {

            const session = localStorage.getItem('session');
            const decrypt = new JSEncrypt();
            decrypt.setPrivateKey(keys.publicKeyBackoffice);
            const bandSession = decrypt.decrypt(session);

            this.rol = bandSession


        },


        Imprimir(e) {

            let idButton = (e.target.id);

            if (idButton === 'print') {
                // Obtén una referencia al botón
                const printButton = document.querySelector('.buttons-print');

                // Simula un evento de clic en el botón
                printButton.click();

            }else if(idButton === 'xlxs'){
                
                const excelButton = document.querySelector('.buttons-excel');
                excelButton.click();
            }
        }



    },

    mounted() {


        this.descifra()

        this.getLista();


        setInterval(() => {
            this.loading2 = false
            this.ocultar = true

        }, 3000);

        setInterval(() => {
            this.loading = false //este es de la tabla

        }, 6000)
    }
}
</script>

<style lang="scss">
@import "datatables.net-dt";
@import 'datatables.net-responsive-dt';

.btn-spaced {
    margin-right: 10px;
    /* Ajusta el valor según el espaciado deseado */
}

.btn-spaced:last-child {
    margin-right: 0;
}


/*Con esto oculto los botones del datagrid*/
.btn-group {
    display: none !important;
}


.dataTables_wrapper .dataTables_filter {
    text-align: left !important;
    width: 40% !important;
}

.btn-title {
    display: flex;
    align-items: center;
    justify-content: center;
}


/* Estilos específicos para dispositivos móviles */
@media only screen and (max-width: 767px) {
    .dataTables_wrapper .dataTables_filter {
        text-align: left !important;
        width: 100% !important;
    }

    .centra-botones {

        display: flex;
        justify-content: center;
        align-items: center;

    }
}
</style>