 <template>
  <div class="mt-2" style="padding-left: 10px; padding-right: 10px; padding-left: 10px;">
    <div class="row"> 
     
      <div class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12">
        <swiper :slides-per-view="3" :space-between="15" :centered-slides="false" :navigation="false" :pagination="false"
          :breakpoints="{
            200: {
              slidesPerView: 1.1,
            },
            400: {
              slidesPerView: 1.1,
            },
            550: {
              slidesPerView: 2.5,
            },
            768: {
              slidesPerView: 3.5,
            },
            1024: {
              slidesPerView: 2.5,
            },
            1200: {
              slidesPerView: 4,
            },
          }">
          <swiper-slide>
            <cant-enviados />
          </swiper-slide>
          <swiper-slide>
            <cant-lineas-inactivas />
          </swiper-slide>
          <swiper-slide>
            <lineas-aut-cant />
          </swiper-slide>
        </swiper>
      </div>
    </div>





    <div class="row">
      <div class="col-12 col-sm-12 col-lg-4 col-md-4 col-xl-3" align="center"
        style="padding-top:10px; overflow: auto; margin: 0 auto;">
        <qr />
        <!-- <Link-datos class="d-md-block" :prueba="abcd" style="margin-top:10px;" /> -->
      </div>

      <div class="col-12 col-sm-12 col-lg-8 col-md-8 col-xl-9" style="margin-top:10px; overflow: auto;">
        <Listado-no-enviados :prueba="abcd" />
      </div>
    </div>










  </div>
</template>
<script>
/*eslint-disable */
import Qr from "./CargaQR.vue";
import LinkDatos from "./LinkDatos.vue";
import CantEnviados from "./EnviadosCant.vue";
import CantLineasInactivas from "./LineasInactivasCant.vue";
import LineasAutCant from "./LineasAutCant.vue";
import ListadoNoEnviados from "./ListadoNoEnviados.vue";




import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
export default {
  name: "dashboard-default",
  data() {


    return {
      showModal: false,
      showModal2: false,
      isMobile: this.$isMobile,
      loading: true,
      abcd: JSON.parse(localStorage.getItem('userData')).ruc,
      displayName: '',
      tokenAuth: JSON.parse(localStorage.getItem('userData')).user,
      componentKeyFormVenta: 500

    }
  },
  components: {
    Qr,
    CantEnviados,
    CantLineasInactivas,
    LineasAutCant,
    ListadoNoEnviados,
    LinkDatos,
    Swiper, SwiperSlide
  },

  methods: {

    prueba22() {
      console.log('recibe prueba22')
      //this.$eventBus.emit('showCargaQR')
    },
 
    closed() {
      this.$eventBus.emit('reiniciarFormVentaDashboard');

    },
 
    async cargaCantidadSms() {


      //console.log('cargaCantidadSms');
      await this.$https.get('/api/auth/getCantidadesEnvios/' + this.tokenAuth).then(response => {
        /* console.log(response.data)
        console.log('contadorEnvios: ' + response.data.contadorEnvios);
        console.log('contadorNoenviados: ' + response.data.contadorNoenviados);
        console.log('cantidadLineas: ' + response.data.cantLineas); */ 

        let datosCant = { contadorEnvios: response.data.contadorEnvios, contadorNoenviados: response.data.contadorNoenviados, contadorLineas: response.data.cantLineas }

        this.$eventBus.emit('showEnviosSms', datosCant);

      })


    }
  },

  created() {

  },

  beforeUnmount() {


  },
  activated() {

    
    this.cargaCantidadSms()

    //Aqui me actualiza la lista
    this.$eventBus.emit('showCargaLista')

  },
  deactivated() {
    console.log('About has been deactivated')
  },



  mounted() {

    if (this.isMobile) {
      this.$eventBus.emit('activeShowMenu')
    }

    this.cargaCantidadSms()

    //console.log('Carga Dashboard');

    //Aqui me actualiza la lista
    this.$eventBus.emit('showCargaLista')

  },
};
</script>

<style></style>