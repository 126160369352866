<template>
    <div>

        <!-- <form role="form" autocomplete="off"> -->

        <div class="row">


            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7" style="margin-bottom:10px">
                <label for="example-text-input" class="form-control-label"
                    style="font-weight: bold; font-size: 12px; ">Nombre de la campaña:</label>
                <input placeholder="Ingrese su campaña" class="form-control col-lg-12" autocomplete="off"
                    v-model="nameCampana" @input="nameCampana = $event.target.value.toUpperCase()" />
            </div>


            <div class="row mt-3">

                <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">

                    <p style="margin:10px; font-weight: bold;"><i style="margin-right: 10px;" class="fas fa-list"></i>
                        LISTADO DE CAMPAÑAS</p>

                </div>


                <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1" align="center" style="display:flex">
                    <Skeletor v-if="loading" :shimmer="true" height="20" width="100%" style="margin:auto" />
                    <i v-else @click="refresh" style="cursor:pointer; margin:auto"
                        class="fas fa-sync text-sm opacity-10"></i>
                </div>

            </div>

            <div v-if="loading" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px">

                <div class="row">

                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                        <Skeletor :shimmer="true" height="40" width="50%" />

                    </div>

                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                        <Skeletor :shimmer="true" height="10" pill width="30%" style="margin-bottom: 10px;" />
                        <Skeletor :shimmer="true" height="40" width="100%" />

                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <table class="table tablesorter">
                            <thead class="thead-light">
                                <tr>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>
                                    <th>
                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                    </th>

                                </tr>
                            </thead>
                            <tbody>


                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>



                                </tr>
                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>


                                </tr>
                                <tr>

                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>
                                    <td>
                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                    </td>




                                </tr>

                            </tbody>
                        </table>

                    </div>

                    <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                        <Skeletor :shimmer="true" height="10" pill width="80%" />
                    </div>

                    <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">

                        <div class="row">
                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <Skeletor :shimmer="true" height="10" width="100%" />
                            </div>

                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" align="center">
                                <Skeletor :shimmer="true" circle size="30" />
                            </div>

                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                <Skeletor :shimmer="true" height="10" width="100%" />
                            </div>


                        </div>


                    </div>

                </div>

            </div>

            <DataTable v-else :columns="columns" :options="options" :data="transactions" :createdRow="createdRowHandler"
                class="table tablesorter" width="100%">
                <thead>
                    <tr>
                        <th>#</th>
                        <th data-priority="1" style="display:none">fk</th>
                        <th data-priority="2">Nombres</th>
                        <th data-priority="3">Acción</th>

                    </tr>
                </thead>


            </DataTable>




            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3" align="center">

                <!-- <button type="submit" id="btn_submit" :disabled="isActive" class="btn btn-primary" v-on:click="saveInfo()"
                    style="width: 100%;"> Guardar <i style="margin-left: 10px;" class="fas fa-arrow-circle-right"></i>
                </button> -->

                <a class="btn btn-primary btn-sm" v-on:click="saveInfo()" style="width:100%;"> Guardar
                    <i style="margin-left: 10px;" class="fas fa-arrow-circle-right"></i>
                </a>

            </div>


        </div>




        <!-- </form> -->

    </div>
</template>

<script>
/* eslint-disable */
import DataTable from 'datatables.net-vue3'
import DataTablesCore from 'datatables.net-bs5';
import 'datatables.net-responsive';

DataTable.use(DataTablesCore);

export default {
    name: "formularioCampanas-default",
    components: {


        DataTable


    },
    //    props: ['prueba'],
    data() {


        return {
            Authorization: localStorage.getItem("authorization"),
            loading: true,
            results: null,
            tokenAuth: null,
            isActive: true,
            nameCampana: '',
            transactions: [],
            columns: [
                {
                    data: null,
                    render: function (data, type, row, meta) {
                        const index = meta.row + 1;
                        return index;
                    }
                },
                { data: 'id_campanas', visible: false },
                { data: 'nombre' },
                {
                    data: null, render: function (data, type, row, meta) {
                        return `<a data-id="${data.fk}" class="opcionClick btn btn-primary btn-sm" style="width:100%;"><i class="fas fa-trash"> </i></a>`;
                    }
                }
            ],
            options: {
                searching: false, //Cuadro de busqueda
                responsive: true,
                lengthChange: false, //Filtro
                "ordering": false,
                columnDefs: [
                    { responsivePriority: 1, targets: 0 },
                    { responsivePriority: 2, targets: -1 }
                ],
                lengthMenu: [
                    [3, 5, 15, 25, 35, 50, -1],
                    [3, 5, 15, 25, 35, 50, "All"],
                ],
                pageLength: 3,
                "language": {
                    "search": "Buscar:",
                    "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                    "searchPlaceholder": "Ingrese algún dato",
                    "zeroRecords": "Lo sentimos, no se encontraron resultados",
                    "processing": "Buscando. Espere por favor...",
                    "sLengthMenu": "Mostrar _MENU_ registros",
                    "oPaginate": {
                        "sFirst": "Primero",
                        "sLast": "Último",
                        "sNext": "Siguiente",
                        "sPrevious": "Anterior"
                    },

                }
            },

        }
    },
    computed: {

    },
    watch: {

    },

    activated() {

        


    },

    mounted() {

        this.tokenAuth = JSON.parse(localStorage.getItem('userData')).user

        console.log('carga campaña');
        console.log(this.tokenAuth);
        this.cargarCampanas()


        //$(document).on("click", ".consultar", (evt) => {
        //utilizando off() para eliminar cualquier otro manejador de eventos click() que haya sido previamente vinculado al selector ".consultar"
        $(document).off("click", ".opcionClick")
        $(document).on("click", ".opcionClick", (evt) => {

            console.log('ingresa a consultar');
            const data = $(evt.target).data('id');
            console.log(data);

            this.deleteCampana(data)

        });


    },
    methods: {
        showSuccessToast(message) {
            this.$toast.success(message, {
                position: "top-right",
                max: 10
            });
        },

        showErrorToast(message) {
            this.$toast.error(message, {
                position: "top-right",
                max: 10,
            });
        },

        deleteCampana(data) {
            this.showErrorToast('Función no disponible por el momento')
        },

        async cargarCampanas() {

            console.log('async cargarCampanas');
            this.transactions = []

            const { data } = await this.$https.get('api/auth/getCampanas/' + this.tokenAuth, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${this.Authorization}`,
                        // Otros encabezados si es necesario
                    },
                });

            if (data.code == 204) {

                this.transactions = []
                
            } else if (data.code == 200) {                

                this.transactions = data.data

            }

            this.loading = false
            this.isActive = false

        },

        async saveInfo() {
            console.log('Ingresa saveInfo');

            if (this.nameCampana == '') {
                return this.showErrorToast('El nombre de la campaña no debe estar vacio')
            }

            const { data } = await this.$https.post('api/auth/aggCampanas', { tokenAuth: this.tokenAuth, nombreCampana: this.nameCampana });
            //console.log(data);


            if (data.code == 206) {
                return this.showErrorToast(data.message)
            } else if (data.code == 200) {
                this.cargarCampanas()
                this.$eventBus.emit('showCargaCampana', this.nameCampana);
                return this.showSuccessToast(data.message)
            } else {
                return this.showErrorToast('Ha ocurrido un error')
            }


        },
    }

}
</script>


<style lang="scss">
@import "datatables.net-dt";
@import 'datatables.net-responsive-dt';

.btn-sm {
    font-size: 12px !important;
    padding: 4px 8px !important;
}

.btn-square {
    width: 5px;
    height: 25px;
    padding: 0;
    line-height: 1;
    text-align: center;
}

.btn-success {
    background: #00b63e !important;
}
</style>