<!-- eslint-disable vue/comment-directive -->
<template>
    <div class="container-fluid" style=" padding: 0px; ">
        <div class="row" style="width: 100%;margin:0px">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px">
                <nav class="navbar navbar-expand-lg navbar-dark bg-dark"
                    style="height: 250px;display:block;    margin: 0px !important">

                    <div>
                        <router-link to="/developers">
                            <h2 class="mytext" style="color: white">{{ nameEmpresa }}</h2>
                        </router-link>
                    </div>


                    <div class="mt-5" lg="7">
                        <div class="container" style=" padding: 0px; ">
                            <div class="row justify-content-center">
                                <div class="col-12 col-md-8 col-lg-7">
                                    <div class="card text-center shadow">
                                        <div class="card-header">
                                            <Skeletor v-if="loading" :shimmer="true" height="30" width="100%" pill
                                                style="margin-bottom:10px" />
                                            <h4 v-else class="d-flex align-items-center justify-content-center m-top2">
                                                FORMULARIO DE REGISTRO
                                                <i class="fas fa-user-edit ms-2"></i>
                                            </h4>


                                        </div>
                                        <div class="card-body">
                                            <form autocomplete="off" @submit.prevent="registrar"
                                                style=" margin-top: -10px; ">
                                                <div class="row justify-content-center">


                                                    <div class="col-8 col-sm-8 col-md-6 col-lg-6 ">
                                                        <div class="input-group">
                                                            <Skeletor v-if="loading" :shimmer="true" height="35" width="75%"
                                                                pill
                                                                style="border-right: 1px; border-radius: 6px 6px 6px 6px;" />

                                                            <input v-else placeholder="Ingrese su cédula"
                                                                :disabled="isDisabled3" class="form-control text-center"
                                                                required type="tel" @keypress="NumbersOnly"
                                                                autocomplete="off" @paste="handlePaste" v-model="user.dni"
                                                                maxlength="10"
                                                                style="border-right: 1px solid rgba(0, 0, 0, 0.25); border-radius: 6px 6px 6px 6px;" />

                                                            <div class="input-group-append ">

                                                                <Skeletor v-if="loading" :shimmer="true" height="35"
                                                                    width="180%" pill style="margin-left: 10px;" />

                                                                <span v-else class="input-group-text mt-1" @click="checkDNI"
                                                                    style="cursor: pointer; border-right: 1px solid rgba(0, 0, 0, 0.25); margin-left: 10px;">
                                                                    <i class="fas fa-search"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div class="col-9 col-md-9 col-lg-9 mt-3" v-show="ocultar">

                                                        <input :disabled="isDisabled" v-model="user.name" autocomplete="off"
                                                            class="form-control text-center col-lg-12" type="text" />
                                                    </div>

                                                </div>



                                                <div class="row justify-content-center mt-3" style="margin-bottom:20px"
                                                    v-show="ocultar">
                                                    <div class="col-12 col-md-8 col-lg-8 ">


                                                        <MazPhoneNumberInput type="tel" default-country-code="EC"
                                                            style="width: 100%;" no-flags no-example required
                                                            autocomplete="off" show-code-on-list size="sm"
                                                            v-model="phoneNumber" color="info" @update="results = $event"
                                                            :translations="{
                                                                countrySelector: {
                                                                    placeholder: 'Código',
                                                                    error: 'Elija un pais',
                                                                },
                                                                phoneInput: {
                                                                    placeholder: 'Nro. celular',
                                                                    example: 'Ejemplo:',
                                                                },
                                                            }" />


                                                    </div>



                                                    <div class="col-6 col-sm-12 col-md-6 col-lg-6 col-xl-6" align="center">

                                                        <hr class="horizontal dark" />


                                                        <button id="btn_update" :disabled="isDisabled2" type="submit"
                                                            class="btn btn-primary"
                                                            style="width: 100%;background: #344767 !important;">
                                                            Registrarse! </button>
                                                    </div>



                                                </div>


                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </nav>
            </div>
        </div>
    </div>
</template>

<script>
/*eslint-disable */


export default {
    name: "Registro",
    components: {

    },

    data() {

        let promoCode = this.$route.params.promoCode;

        return {
            getData: null,
            loading: true,
            phoneNumber: null,
            promoCode: promoCode,
            countryCode: '',
            results: null,
            isDisabled: true,
            isDisabled2: true,
            isDisabled3: false,
            dniRespaldo: "",
            isActive: null,
            ocultar: false,//false,
            nameEmpresa: null,
            user: {
                dni: null,
                name: null,
                fechaNac: null,
                direccion: null
            }
        }

    },

    watch: {
        results(newValue) {
            if (newValue.isValid) {
                //console.log('if');
                this.isActive = true

                this.phoneNumber = newValue.e164
                this.countryCode = newValue.countryCode

                this.isDisabled2 = false

            } else {
                //console.log('else');
                this.isActive = false

                this.countryCode = ''
                this.code = ''

                this.isDisabled2 = true

            }
        },
    },

    methods: {
        showSuccessToast(message) {
            this.$toast.success(message, {
                position: "top-right", //"bottom-right",
                max: 10
            });
        },

        showErrorToast(message) {
            this.$toast.error(message, {
                position: "top-right", //"bottom-right",
                max: 10
            });
        },
        //ingresa solo numeros sin el .
        NumbersOnly(event) {
            const charCode = event.which ? event.which : event.keyCode;
            if ((charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) || event.key === ".") {
                event.preventDefault();
            }
        },

        //evita el copiado de letras y solo pega numeros
        handlePaste(event) {
            const pastedData = event.clipboardData.getData('text/plain');
            const numbersOnly = pastedData.replace(/\D/g, '');
            event.preventDefault();
            document.execCommand('insertText', false, numbersOnly);
        },

        async checkDNI() {
            if (this.user.dni == '') {

                this.dniRespaldo = "";

            } else if (this.user.dni != this.dniRespaldo) {

                console.log(this.user.dni)


                this.$swal({

                    html:
                        '<div class="row"><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="loader"> </div></div><div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><p style="margin:0px; font-size:16px; text-align:center; font-weight:bold">Consultando, espere</p></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div></div>',
                    showCloseButton: false,
                    showCancelButton: false,
                    focusConfirm: false,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    position: 'center',
                });


                let enviaData = {
                    dni: this.user.dni,
                    autorizacion: 'pvea1992'
                }

                const response = await this.$https.post('api/auth/getSearchMineriaDatos', enviaData);
                const data = response.data;

                console.log(data.code);

                if (data.code === 200) {
                    this.$swal.close();
                    this.ocultar = true
                    this.user.name = data.datos.nombres;

                    //esto debe ir en true para bloquear caja
                    //this.isDisabled3 = true;

                } else if (data.code === 208) {
                    this.$swal.close();



                    this.$swal({
                    title: "Error en la consulta, vuelva a intentar",
                    icon: 'question',
                    width: '400px',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        //Esta propiedad te permite definir una función que se ejecuta alguna acción antes de confirmar, si es necesario
                    },
                    allowOutsideClick: () => !this.$swal.isLoading(),
                }).then((result) => {
                    if (result.isConfirmed) {
                        //this.filtraMensaje()
                        console.log('Mensajes enviados');
                    }
                });



                    //return this.showErrorToast('Error en la consulta, vuelva a intentar')
                } else if (data.code === 206) {
                    this.$swal.close();
                    return this.showErrorToast('Cédula incorrecta')
                }


            }


        },

        async getEmpresa() {

            console.log(this.promoCode);

            const response = await this.$https.get(`api/auth/empresa/${this.promoCode}`);
            const data = response.data;

            console.log(data);



            if (data.code === 200 && data.estatus == 'true' && data.formDatos == 1) {
                this.nameEmpresa = data.nombreEmpresa
                this.loading = false
            } else {

                this.$router.push({ name: 'NotAutorizado' });

            }

        },

        async registrar() {
            if (!this.isActive) {
                return this.showErrorToast('El móvil no es correcto')
            }

            console.log(this.user.name);


            this.$swal({

                html:
                    '<div class="row"><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="loader"> </div></div><div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><p style="margin:0px; font-size:16px; text-align:center; font-weight:bold">Por favor, espere...</p></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div></div>',
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                position: 'center',
            });


            let enviaDatos = {
                tokenAuth: this.promoCode,
                dni: this.user.dni,
                movil: this.phoneNumber
            }

            const response = await this.$https.post('api/auth/getSaveFormulario', enviaDatos);
            const data = response.data;

            if (data.code == 200) {
                console.log('Registro OK');
                this.$swal.close();
                this.limpiar()


                this.$swal({
                    title: "Registro éxitoso",
                    icon: 'success',
                    width: '400px',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        //Esta propiedad te permite definir una función que se ejecuta alguna acción antes de confirmar, si es necesario
                    },
                    allowOutsideClick: () => !this.$swal.isLoading(),
                }).then((result) => {
                    if (result.isConfirmed) {
                        //this.filtraMensaje()
                        console.log('Mensajes enviados');
                    }
                });



            } else if (data.code == 204) {
                this.$swal.close();
                this.limpiar()
                console.log('Ya existe un registro el día de hoy');
                //return this.showErrorToast('Ya existe un registro el día de hoy')



                this.$swal({
                    title: "Ya existe un registro el día de hoy",
                    icon: 'error',
                    width: '400px',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        // Esta propiedad te permite definir una función que se ejecuta alguna acción antes de confirmar, si es necesario
                    },
                    allowOutsideClick: () => !this.$swal.isLoading(),
                }).then((result) => {
                    if (result.isConfirmed) {
                        // this.filtraMensaje()
                        console.log('Error en el registro');
                    }
                });






            }



        },
        limpiar() {

            this.phoneNumber = null;
            this.ocultar = false;
            this.user.dni = null;
            this.user.name = null;

        },
    },
    mounted() {
        //let promoCode = this.$route.params.promoCode;

        //console.log('promoCode: ' + promoCode);

        this.getEmpresa()


        /* // Seleccionar el elemento que deseas eliminar
        var element = document.querySelector('.input-group .input-group-text');

        

        // Verificar si el elemento existe antes de intentar eliminarlo
        if (element) {
            // Eliminar el elemento del DOM
            element.remove();
        } */
    }

}

</script>

<style scoped>
.imagen {
    width: 200px;
    /* Adjust the width as per your requirements */
    height: auto;
    /* Maintain aspect ratio */
}

.mytext {
    font-family: sans-serif;
    text-align: left;
}



@media screen and (max-width: 769px) {
    .m-top {
        margin-top: 2rem;
    }

    .mytext {
        font-family: Arial;
        text-align: center;
    }

    .m-top2 {
        font-size: 14px;
    }

    .imagen {
        width: 30%;
        height: auto;
        /* Maintain aspect ratio */
    }

    .bg-dark {
        height: 140px !important;
    }

}
</style>