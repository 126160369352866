
<template>

                <div class="card" style="margin-bottom:10px">

                    <div class=" card-body">

                    <div class="row">

                        <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11"> 

                            <p style="margin:10px; font-weight: bold;"><i style="margin-right: 10px;" class="fas fa-list"></i> Listado de referidos</p>

                        </div>

                        <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1" align="center" style="display:flex"> 
                            <Skeletor v-if="loading" :shimmer="true" height="20" width="100%" style="margin:auto" />
                            <i v-else @click="refresh" style="cursor:pointer; margin:auto" class="fas fa-sync text-sm opacity-10"></i>
                        </div>

                    </div>

                    <div v-if="loading" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px"> 

                        <div class="row">

                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"> 

                                <Skeletor :shimmer="true" height="40" width="50%" />

                            </div>

                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"> 

                                <Skeletor :shimmer="true" height="10" pill width="30%" style="margin-bottom: 10px;" />
                                <Skeletor :shimmer="true" height="40" width="100%" />

                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> 
                            <table class="table tablesorter" >
                                    <thead class="thead-light">
                                    <tr>
                                        <th> <Skeletor :shimmer="true" height="10" width="100%" /></th>
                                        <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                                        <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                                        <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                                        <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                                    </tr>
                                    </thead>
                                    <tbody>


                                    <tr>

                                        <td><Skeletor :shimmer="true" height="20" width="100%" /> </td>
                                        <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                                        <td><Skeletor :shimmer="true" height="20" width="100%" /> </td>
                                        <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                                        <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                                
                                        

                                

                                    </tr>
                                    <tr>
                                                
                                                <td><Skeletor :shimmer="true" height="20" width="100%" /> </td>
                                                <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                                                <td><Skeletor :shimmer="true" height="20" width="100%" /> </td>
                                                <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                                                <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>

                                            



                                            </tr>
                                            <tr>
                                                
                                                <td><Skeletor :shimmer="true" height="20" width="100%" /> </td>
                                                <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                                                <td><Skeletor :shimmer="true" height="20" width="100%" /> </td>
                                                <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>
                                                <td> <Skeletor :shimmer="true" height="20" width="100%" /></td>

                                            



                                            </tr>
                            
                                    </tbody>
                                </table>

                                </div>

                                <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8"> 
                                    <Skeletor :shimmer="true" height="10" pill width="80%" />
                                    </div>

                                    <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4"> 

                                        <div class="row"> 
                                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"> 
                                                <Skeletor :shimmer="true" height="10" width="100%" /> 
                                                </div>

                                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" align="center"> 
                                                    <Skeletor :shimmer="true" circle size="30" /> 
                                            </div>

                                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"> 
                                                <Skeletor :shimmer="true" height="10" width="100%" /> 
                                            </div>


                                        </div>


                                    </div>

                        </div>

                    </div>

                    <DataTable
                    v-else
                    :columns="columns"
                    :options="options"
                    :data="transactions"
                    class="table tablesorter"
                    width="100%"
                    >
                    <thead>
                        <tr>
                        <th data-priority="1">Fecha/Hora</th>
                        <th data-priority="2">Nombres y apellidos</th>
                        <th>Contacto</th>

                        </tr>
                    </thead>

                    <tbody>

                            
                            <tr v-for="transaction in transactions" :key="transaction.id">

                            <td> {{ transaction.dateFormated }} </td>
                            <td> {{ transaction.displayNameReferido }}</td>
                            <td> {{ transaction.contacto }}</td>
                        


                            </tr>
                        </tbody>

                    </DataTable>


                    </div>

                    </div>



</template>

<script>
/*eslint-disable */
import DataTable from 'datatables.net-vue3'
import DataTablesCore from 'datatables.net-bs5';
import 'datatables.net-responsive';


DataTable.use(DataTablesCore);

export default {
    name: "Referidos",
    components: {

        DataTable


        },
        props: ['userId', 'tokenAuth'],
    data() {

     

        return {
        
    
            loading:true,
            transactions:[],
            columns : [
                { data: 'dateFormated' },
                { data: 'displayNameReferido' },
                { data: 'contacto' },
                ], options : {
                                    responsive: true,
                                    "ordering": false,
                                    columnDefs: [
                                                            { responsivePriority: 1, targets: 0 },
                                                            { responsivePriority: 2, targets: -1 }
                                                        ],
                                    lengthMenu: [
                                    [5,15,25, 35, 50, -1],
                                    [5,15,25, 35, 50, "All"],
                                    ],
                                    pageLength: 5, 
                                    "language": {
                                                        "search": "Buscar:",
                                                        "sInfo":      "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                                                            "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                                        "searchPlaceholder": "Ingrese algún dato",
                                                        "zeroRecords": "Lo sentimos, no se encontraron resultados",
                                                        "processing": "Buscando. Espere por favor...",
                                                        "sLengthMenu":     "Mostrar _MENU_ registros",
                                                        "oPaginate": {
                                                                "sFirst":    "Primero",
                                                                "sLast":     "Último",
                                                                "sNext":     "Siguiente",
                                                                "sPrevious": "Anterior"
                                                            },
            
                                                        }
                                },
           
        }
    },
    methods: {
   
        refresh() {
        this.$eventBus.emit('reiniciarListadoReferidos')
      },

      getListado(){


            const userIdEncripted = this.$encryptBackoffice.encrypt(this.userId)

            this.$https.post('/referidos/getReferidos/', { tokenAuth: this.tokenAuth, userId: userIdEncripted }).then(response => {


            if (response.data.code == 200) {
            this.loading = false;



            this.transactions= response.data.transactions;

            } else {




            if (response.data.code == 401) {

       

                    localStorage.removeItem('userData')
this.$store.state.user="";


                // Redirect to login page
                this.$router.push({ name: 'login' })
            } else {

                if (response.data.code == 404) {
                    this.loading = false;



                }else{
                    this.getListado();
                }
                
            }
            }
            }).catch(error => {
                    this.getListado();
            })
            },

    },
    mounted() {

        this.getListado();


}
}    
</script>


<style scoped>

@import "datatables.net-dt";
@import 'datatables.net-responsive-dt';

</style>