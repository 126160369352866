<template>
    <div class="mt-3">
        <vue-final-modal v-model="showModal" classes="modal-container" content-class="modal-content">


            <div class="modal__content">

                <formulario-camapanas v-if="showModal" />



            </div>
        </vue-final-modal>

        <div class="container-fluid" style="padding:0px">
            <div class="row" style="width: 100%; margin: 0;">
                <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                    <div class="card">
                        <div class="card-body">


                            <div class="centra-botones">
                                <button type="button" class="btn btn-primary btn-sm mx-1 mt-2" v-on:click="ValidarMsj()"><i
                                        class="fa fa-whatsapp" style="margin-right: 5px;"></i>Enviar mensajes</button>
                                <!-- <button type="button" class="btn btn-primary btn-sm mx-1 mt-2" v-on:click="openFileDialog"><i
                                    class="fa fa-paperclip" style="margin-right: 5px;"></i>Adjuntar</button> -->
                                <input ref="fileInput" type="file" style="display: none;" v-on:change="handleFileUpload">

                                <button type="button" class="btn btn-primary btn-sm mx-1 mt-2"
                                    v-on:click="openFileDialogExcel">
                                    <i class="fa fa-file-excel-o" style="margin-right: 5px;"></i>
                                    Subir Excel
                                </button>

                                <input ref="fileInput2" type="file" style="display: none;"
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    id="archivoExcel" v-on:change="FileUploadExcel">

                            </div>

                            <hr>

                            <div class="col-lg-12 col-12 col-md-12 col-xl-12 col-sm-12" style="margin-bottom:5px">
                                <label for="example-text-input" class="form-control-label">Detalle del mensaje</label>
                            </div>

                            <div class="row">

                                <div class="col-8 col-sm-6 col-md-8 col-lg-6 col-xl-6" style="margin-bottom:25px">


                                    <v-select placeholder="Seleccione" label="nombre" v-model="selectedOption"
                                        :options="optionsCampanas" :searchable="true" @option:selected="setSelected">
                                    </v-select>

                                </div>


                                <div class="col-4 col-sm-6 col-md-4 col-lg-6 col-xl-6 mt-3" style="margin-bottom:25px">

                                    <i style="cursor:pointer; font-size: 20px !important"
                                        class="fa fa-plus-circle text-sm opacity-10" v-on:click="campanaShow"></i>


                                </div>

                                <div class="col-lg-12 col-12 col-md-12 col-xl-12 col-sm-12" style="margin-bottom:25px">
                                    <textarea class="form-control" v-model="messageSend"
                                        placeholder="Escriba aquí su mensaje..." rows="8"></textarea>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>


                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 m-top">
                    <div class="card">
                        <div class="card-body">


                            <div class="col-12" style="margin-bottom:10px">
                                <label for="example-text-input" class="form-control-label">Tipo de envío</label>

                                <select ref="mySelect" @change="getOptionValue" class="form-select"
                                    placeholder="Seleccione una opción" required>
                                    <option selected value="">Seleccione una opción</option>
                                    <option value="Texto">Texto</option>
                                    <option value="Imagen">Imagen</option>
                                    <!-- <option value="Video">Video</option> -->
                                    <option value="Documento">Documento</option>
                                </select>

                            </div>

                            <div class="card-body">
                                <v-lazy-image v-if="isVideo" :src="imagen"
                                    style="object-fit: cover;object-position: top;height: 100%;width: 100%;" />
                                <!-- <vue-lazy-load-video :src="imagen"
                                    style="object-fit: cover;object-position: top;height: 100%;width: 100%;" /> -->

                                <video v-else ref="myVideo" controls :src="videoCont" isVideo
                                    style="object-fit: cover;object-position: top;height: 100%;width: 100%;"></video>
                            </div>

                        </div>
                    </div>
                </div>



                <div class="row">
                    <div class="col-8 col-sm-4 col-md-4 col-lg-4 col-xl-4 mt-3" align="center">

                        <button type="button" class="btn btn-primary btn-sm" @click="downloaderExcel" style="width: 100%; ">
                            Descargar formato de Plantilla
                        </button>

                    </div>


                </div>



                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-top">
                    <div class="card">



                        <div class=" card-body">

                            <div class="row">

                                <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">

                                    <p style="margin:10px; font-weight: bold;"><i style="margin-right: 10px;"
                                            class="fas fa-list"></i> LISTADO DE MENSAJES A ENVIAR</p>

                                </div>


                                <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1" align="center" style="display:flex">
                                    <Skeletor v-if="loading" :shimmer="true" height="20" width="100%" style="margin:auto" />
                                    <i v-else @click="refres" style="cursor:pointer; margin:auto"
                                        class="fas fa-sync text-sm opacity-10"></i>
                                </div>

                            </div>

                            <div v-if="loading" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px">

                                <div class="row">

                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                                        <Skeletor :shimmer="true" height="40" width="50%" />

                                    </div>

                                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                                        <Skeletor :shimmer="true" height="10" pill width="30%"
                                            style="margin-bottom: 10px;" />
                                        <Skeletor :shimmer="true" height="40" width="100%" />

                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <table class="table tablesorter">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th>
                                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                                    </th>
                                                    <th>
                                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                                    </th>
                                                    <th>
                                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                                    </th>

                                                    <th>
                                                        <Skeletor :shimmer="true" height="10" width="100%" />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>


                                                <tr>

                                                    <td>
                                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                                    </td>
                                                    <td>
                                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                                    </td>
                                                    <td>
                                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                                    </td>

                                                    <td>
                                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                                    </td>





                                                </tr>
                                                <tr>

                                                    <td>
                                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                                    </td>
                                                    <td>
                                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                                    </td>
                                                    <td>
                                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                                    </td>

                                                    <td>
                                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                                    </td>





                                                </tr>
                                                <tr>

                                                    <td>
                                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                                    </td>
                                                    <td>
                                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                                    </td>
                                                    <td>
                                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                                    </td>

                                                    <td>
                                                        <Skeletor :shimmer="true" height="20" width="100%" />
                                                    </td>


                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>

                                    <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
                                        <Skeletor :shimmer="true" height="10" pill width="80%" />
                                    </div>

                                    <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">

                                        <div class="row">
                                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                <Skeletor :shimmer="true" height="10" width="100%" />
                                            </div>

                                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" align="center">
                                                <Skeletor :shimmer="true" circle size="30" />
                                            </div>

                                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                <Skeletor :shimmer="true" height="10" width="100%" />
                                            </div>

                                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                <Skeletor :shimmer="true" height="10" width="100%" />
                                            </div>


                                        </div>


                                    </div>

                                </div>

                            </div>

                            <DataTable v-else :columns="columns" :options="options" :data="transactions"
                                class="table tablesorter" width="100%">
                                <thead>
                                    <tr>
                                        <th style="width: 5%">#</th>
                                        <th data-priority="1" style="width: 20%">NOMBRE</th>
                                        <th style=" text-align: center; width: 20%">MOVIL</th>
                                        <th style=" text-align: center; width: 8%">VARIABLE1</th>
                                        <th style=" text-align: center; width: 8%">VARIABLE2</th>
                                        <th style=" text-align: center; width: 8%">VARIABLE3</th>
                                    </tr>
                                </thead>





                            </DataTable>


                        </div>


                    </div>
                </div>

            </div>





        </div>
    </div>
</template>

<script>
/* eslint-disable */
import FormularioCamapanas from "./FormularioCampanas.vue";
import reasXlsFile from "read-excel-file";
import DataTable from 'datatables.net-vue3'
import DataTablesCore from 'datatables.net-bs5';
import 'datatables.net-responsive';


DataTable.use(DataTablesCore);

export default {

    name: "formularioEnvios-default",
    components: {
        DataTable,
        FormularioCamapanas
    },

    data() {


        return {
            Authorization: localStorage.getItem("authorization"),
            loading: false,
            showModal: false,
            isMobile: this.$isMobile,
            SendImagenServe: '',
            rutaImagen: '',
            tokenAuth: JSON.parse(localStorage.getItem('userData')).user,
            items: [],
            messageSend: '',
            cliente: {
                sender: localStorage.getItem("sender"),
                user: JSON.parse(localStorage.getItem('userData')).user
            },
            imagen: 'https://www.whatsivo.com/wp-content/uploads/2022/06/noimage.jpg',
            isVideo: true,
            optionsCampanas: [],
            selectedOption: null,


            transactions: [],
            columns: [
                { data: 'idRegistro' },
                { data: 'NOMBRE' },
                { data: 'MOVIL', className: 'text-center' },
                { data: 'VARIABLE1', className: 'text-center', defaultContent: "-" },
                { data: 'VARIABLE2', className: 'text-center', defaultContent: "-" },
                { data: 'VARIABLE3', className: 'text-center', defaultContent: "-" },
            ],
            options: {
                responsive: true,
                "ordering": false,
                columnDefs: [
                    { responsivePriority: 1, targets: 0 },
                    { responsivePriority: 2, targets: -1 }
                ],
                lengthMenu: [
                    [5, 15, 25, 35, 50, -1],
                    [5, 15, 25, 35, 50, "All"],
                ],
                pageLength: 5,
                "language": {
                    "search": "Buscar:",
                    "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                    "searchPlaceholder": "Ingrese algún dato",
                    "zeroRecords": "Lo sentimos, no se encontraron resultados",
                    "processing": "Buscando. Espere por favor...",
                    "sLengthMenu": "Mostrar _MENU_ registros",
                    "oPaginate": {
                        "sFirst": "Primero",
                        "sLast": "Último",
                        "sNext": "Siguiente",
                        "sPrevious": "Anterior"
                    },

                }
            },





        }
    },
    computed: {

    },

    watch: {

    },

    mounted() {



    },

    activated() {

        this.$eventBus.on('showCargaCampana', (parametro) => {
            //console.log('Recibe ok: ' + parametro);

            this.cargarCampanas()
            this.selectedOption = { nombre: parametro };
        });

        //console.log('Me carga el formulario envios');
        //console.log(this.isMobile);

        this.cargarCampanas()

    },

    methods: {

        prueba1234() {

            this.$swal.fire({
                html: "<span style='font-size:14px'>Enviando mensajes, por favor espere.. </span>",
                position: "center",
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                    this.$swal.showLoading();
                }
            }).then(
                () => { },
                (dismiss) => {
                    if (dismiss === 'timer') {
                        console.log('closed by timer!!!!');
                        this.$swal.fire({
                            title: 'Finished!',
                            icon: 'success',
                            showConfirmButton: false
                        })
                    }
                })


        },

        showSuccessToast(message) {
            this.$toast.success(message, {
                position: "bottom-right",
                max: 10
            });
        },

        showErrorToast(message) {
            this.$toast.error(message, {
                position: "bottom-right",
                max: 10
            });
        },

        async cargarCampanas() {

            try {

                const { data } = await this.$https.get('api/auth/getCampanas/' + this.tokenAuth, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${this.Authorization}`,
                        // Otros encabezados si es necesario
                    },
                });

                let campanas = ''
                this.optionsCampanas = []

                campanas = data.data
                
                //console.log(campanas);
                this.optionsCampanas = campanas

            } catch (error) {
                console.error(error);
                // Tu código para manejar el error
                console.log("Error al cargar la lista");
                console.log(error.response.status);

                if (error.response.status === 401) {
                    this.$swal
                        .fire({
                            html: "<span style='font-size:14px'>Tu sesión se ha expirado, vuelva a iniciar sesión.. </span>",
                            position: "center",
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            showConfirmButton: false, // Oculta el botón de confirmación
                            timer: 4000, // Duración en milisegundos (4 segundos)
                            didOpen: () => {
                                this.$swal.showLoading();
                            },
                        })
                        .then(
                            () => {
                                // Este bloque se ejecuta después de que se cierre automáticamente la alerta
                                console.log("closed by timer!!!!");

                                localStorage.clear();
                                location.reload(true);
                            },
                            (dismiss) => {
                                if (dismiss === "timer") {
                                    // Este bloque se ejecuta si la alerta se cierra automáticamente por el temporizador
                                    console.log("closed by timer!!!!");
                                    this.$swal.fire({
                                        title: "Finished!",
                                        icon: "success",
                                        showConfirmButton: false,
                                    });
                                }
                            }
                        );
                }
            }

        },

        campanaShow() {
            //console.log('show carga');
            this.showModal = true
        },

        openFileDialog() {
            //console.log('Ingresa abrir');
            this.SendImagenServe = '';

            if (this.$refs.mySelect.value != '') {

                this.$refs.fileInput.click(); // Hace clic en el input de tipo file al hacer clic en el botón

            } else {

                this.showErrorToast('No has seleccionado el tipo de mensaje')

            }
        },

        handleFileUpload(ev) {

            const file = ev.target.files[0];
            this.rutaImagen = ev.target.files[0];
            const allowedTypes = ['image/jpeg', 'image/png'];
            const allowedTypes2 = ['application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
            const allowedTypes3 = ['video/mp4'];
            const selectedOption = this.$refs.mySelect.value

            //console.log('Mi seleccion es: ' + selectedOption);


            if (!file) {
                this.showErrorToast('No se seleccionó ningún archivo');
                return;
            }

            if (selectedOption === 'Imagen') {
                if (!allowedTypes.includes(file.type)) {
                    this.showErrorToast('Tipo de archivo no permitido en imágenes')
                    return;
                }
                if (file.size > 2000000) {
                    this.showErrorToast('El archivo no debe pesar más de 2MB')
                    return;
                }
            }

            if (selectedOption === 'Documento') {
                if (!allowedTypes2.includes(file.type)) {
                    this.showErrorToast('Tipo de archivo no permitido en Documentos')
                    return;
                }
                if (file.size > 2000000) {
                    this.showErrorToast('El archivo no debe pesar más de 2MB')
                    return;
                }
            }

            if (selectedOption === 'Video') {
                if (!allowedTypes3.includes(file.type)) {
                    this.showErrorToast('Tipo de archivo no permitido en Videos')
                    return;
                }
                if (file.size > 50000000) {
                    this.showErrorToast('El video no debe pesar más de 50MB')
                    return;
                }
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = (e) => {
                const fileExtension = file.name.split('.').pop().toLowerCase();
                const isImage = ['jpg', 'jpeg', 'png'].includes(fileExtension);
                const isVideo = ['mp4'].includes(fileExtension);

                if (selectedOption === 'Documento' && !isImage && !isVideo) {
                    this.isVideo = true
                    this.imagen = 'https://www.whatsivo.com/wp-content/uploads/2022/06/adjunto.jpg';
                    this.SendImagenServe = this.imagen

                } else if (selectedOption === 'Video') {
                    this.isVideo = false
                    this.videoCont = e.target.result;
                } else {
                    this.isVideo = true
                    this.SendImagenServe = (e.target.result);
                    this.imagen = e.target.result;
                }
            };



        },

        openFileDialogExcel() {
            // Simular el click en el input de archivo para abrir la ventana de selección
            this.$refs.fileInput2.click();
            this.loading = true
        },
        FileUploadExcel() {
            // Limpiar los registros del grid
            this.items = [];

            // Obtener el input de archivo y validar que se haya seleccionado un archivo
            const input = document.getElementById("archivoExcel");

            // verificar si el archivo es un archivo XLSX
            if (input.files[0].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                this.showErrorToast('Seleccione un archivo XLSX válido.')
                return;
            }

            // Llamar a la función para procesar el archivo
            reasXlsFile(input.files[0]).then((rows) => {
                // Procesar los datos del archivo y almacenarlos en el arreglo "items"
                let userData = JSON.parse(localStorage.getItem("userData"));
                this.userDemo = userData.user

                const MAX_ROWS = this.userDemo === 'demo@whatsivo.com' ? 11 : rows.length;

                for (let i = 1; i < Math.min(MAX_ROWS, rows.length); i++) {
                    this.items.push({
                        idRegistro: i,
                        NOMBRE: rows[i][0],
                        MOVIL: rows[i][1],
                        VARIABLE1: rows[i][2],
                        VARIABLE2: rows[i][3],
                        VARIABLE3: rows[i][4],
                    });
                }
                //console.log(this.items);
                this.transactions = this.items
                this.loading = false
            });
        },

        //Campaña
        setSelected(selectedOption) {
            console.log("Selected option: ", selectedOption.id_campanas);
            // o console.log("Selected option: ", selectedOption.Banco);
        },
        //Texto - Imagen - Documento - Video
        getOptionValue() {
            const selectedOption = this.$refs.mySelect.value;
            //console.log(selectedOption);

            switch (selectedOption) {
                case "Imagen":
                    this.imagen = "https://www.whatsivo.com/wp-content/uploads/2022/06/spinner.gif";
                    this.openFileDialog()
                    break;
                case "Texto":
                    this.isVideo = true
                    this.imagen = "https://www.whatsivo.com/wp-content/uploads/2022/06/noimage.jpg";
                    break;
                case "Documento":
                    this.imagen = "https://www.whatsivo.com/wp-content/uploads/2022/06/spinner.gif";
                    this.openFileDialog()
                    break;
                case "Video":
                    this.imagen = "https://www.whatsivo.com/wp-content/uploads/2022/06/spinner.gif";
                    this.openFileDialog()
                    break;
                default:
                    // Acción por defecto si no hay opción seleccionada
                    this.imagen = "https://www.whatsivo.com/wp-content/uploads/2022/06/noimage.jpg";
                    break;
            }
        },

        ValidarMsj() {

            let loggedIn = localStorage.getItem("loggedIn");

            if (loggedIn == 'NO') {

                this.$router.push({ name: 'Dashboard' })
                this.showErrorToast('Es necesario que vincule el dispositivo, código QR')
                return;

            }


            if (this.messageSend.indexOf('@@NOMBRE@@') === -1) {
                this.showErrorToast('Variable faltante @@NOMBRE@@')
                return
            }

            if (this.selectedOption == null) {
                this.showErrorToast('Escoja una campaña')
                return
            }

            if (this.$refs.mySelect.value === '') {
                this.showErrorToast('Tipo de mensaje no seleccionado')
                return
            }

            if (this.items.length === 0) {
                this.showErrorToast('Archivo Excel no seleccionado')
                return
            }


            const selectedOption = this.$refs.mySelect.value
            const selectedFile = this.SendImagenServe

            if (selectedOption === 'Imagen' && selectedFile === '' || selectedOption === 'Documento' && selectedFile === '') {
                this.showErrorToast('Debe seleccionar un archivo')
                return
            }



            this.$swal({
                title: "¿Seguro que deseas enviar los mensajes?",
                icon: 'question',
                width: '400px',
                showCancelButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    //Esta propiedad te permite definir una función que se ejecuta alguna acción antes de confirmar, si es necesario
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            }).then((result) => {
                if (result.isConfirmed) {

                    this.filtraMensaje()

                }
            });



        },

        filtraMensaje() {

            this.$swal.fire({
                html: "<span style='font-size:14px'>Enviando mensajes, por favor espere.. </span>",
                position: "center",
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                    this.$swal.showLoading();
                }
            }).then(
                () => { },
                (dismiss) => {
                    if (dismiss === 'timer') {
                        console.log('closed by timer!!!!');
                        this.$swal.fire({
                            title: 'Finished!',
                            icon: 'success',
                            showConfirmButton: false
                        })
                    }
                })


            const selectedOption = this.$refs.mySelect.value
            //console.log('Filtro OK');

            let datosForWs = {
                jsonData: this.items,
                textoMensaje: this.messageSend,
                tipoMensaje: selectedOption,
            };

            this.recorreMensajeText(datosForWs)

        },

        async recorreMensajeText(form) {

            try {

                const { jsonData: extraeInfo, textoMensaje: mensaje } = form;
                const sender = localStorage.getItem("sender");
                const campana = this.selectedOption.nombre;
                const selectedOption = this.$refs.mySelect.value

                let shouldBreak = false;

                //console.log(extraeInfo);

                for (const { MOVIL, NOMBRE, VARIABLE1, VARIABLE2, VARIABLE3 } of extraeInfo) {
                    const msjRemplazado = mensaje.replace(/@@(\w+)@@/g, (_, match) => `*${eval(match)}*`);
                    const enviaDtos = { sender, number: MOVIL, message: msjRemplazado };

                    if (selectedOption == 'Texto' && !shouldBreak) {

                        const enviaDtos2 = {
                            enviaDtos,
                            persona: NOMBRE,
                            campana,
                            email: this.cliente.user,
                            tipoSms: this.$refs.mySelect.value
                        }

                        try {
                            const { data } = await this.$https.post('api/auth/sendText', enviaDtos2);
                            //console.log(data);

                            if (data.code === 204) {
                                shouldBreak = true;
                                this.showErrorToast(data.message)
                            }

                            if (data.code === 200) {
                                await this.$sleepTimer.generateTiempo(3000);
                            }

                        } catch (error) {
                            console.error(error.message);
                        }


                    } else if (selectedOption == 'Imagen' || selectedOption == 'Documento' && !shouldBreak) {


                        let data = new FormData();
                        let file = this.rutaImagen;
                        data.append("sender", enviaDtos.sender);
                        data.append("number", enviaDtos.number);
                        data.append("campana", campana);
                        data.append("tipoSms", this.$refs.mySelect.value);
                        data.append("message", enviaDtos.message);
                        data.append("persona", NOMBRE);
                        data.append("email", this.cliente.user);
                        data.append("file", file);
                        let config = {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        };

                        try {

                            const respuesta = await this.$https.post('api/auth/sendMultimedia', data, config);

                            //console.log(respuesta);

                            if (respuesta.data.code === 204) {
                                shouldBreak = true;
                                this.showErrorToast(respuesta.data.message)
                            }

                            if (respuesta.data.code === 200) {
                                await this.$sleepTimer.generateTiempo(3000);
                            }

                        } catch (error) {
                            console.error(error.message);
                        }


                    }



                    if (shouldBreak === true) {
                        // Salir del bucle
                        console.log('Me sale del bucle');
                        break;
                    }


                }


                this.$swal({
                    title: "Mensajes enviados con éxitos",
                    icon: 'success',
                    width: '400px',
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        //Esta propiedad te permite definir una función que se ejecuta alguna acción antes de confirmar, si es necesario
                    },
                    allowOutsideClick: () => !this.$swal.isLoading(),
                }).then((result) => {
                    if (result.isConfirmed) {

                    }
                });



            } catch (e) {
                this.showErrorToast(e.message)
            }



        },

        downloaderExcel() {


            this.$swal({
                title: "La plantilla se descargará!",
                icon: 'info',
                width: '400px',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    //Esta propiedad te permite definir una función que se ejecuta alguna acción antes de confirmar, si es necesario
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            }).then((result) => {
                if (result.isConfirmed) {

                    const url = 'https://www.whatsivo.com/wp-content/uploads/2022/10/publicidad.xlsx';
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'publicidad.xlsx';
                    link.click();

                }
            });


        }

    }

}
</script>

<style lang="scss">
@import "datatables.net-dt";
@import 'datatables.net-responsive-dt';


/* Con esto elimino el scroll horizontal del alert con el loading */
.swal2-popup {
    display: inline-table !important;
}

.swal2-popup.swal2-modal.swal2-loading.swal2-show {
    display: inline-table !important;
}


.swal2-container {
    overflow-x: hidden !important;
    white-space: nowrap !important;
}

.swal2-container {
    overflow-x: hidden;
    white-space: nowrap;
}


.my-swal-popup-class {
    background-color: white;
}

.pintar {
    background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%) !important;
}

@media screen and (max-width: 768px) {
    .m-top {
        margin-top: 5px!important;
    }

    .centra-botones {

        display: flex;
        justify-content: center;
        align-items: center;

    }
}
</style>