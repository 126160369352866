<template>
    <div class="container-fluid">
        <div class="chat-list mt-3">
            <div class="list-group">
                <a href="#" class="list-group-item list-group-item-action" v-for="contact in contacts" :key="contact.name"
                    @click="selectContact(contact.name)">
                    {{ contact.name }}
                </a>
            </div>
        </div>
        <div class="chat-window">
            <div class="chat-header bg-light">
                <h5 class="mb-0 chat-user">{{ selectedUser }}</h5>
            </div>
            <div class="chat-content" ref="chatContent">
                <div v-for="message in filteredMessages" :key="message.id" :class="[message.sender === 'user' ? 'derecha' : 'izquierda']">
                    <div :class="['chat-message', message.sender === 'user' ? 'user-message' : 'contact-message']">
                        {{ message.text }}
                    </div>
                </div>
            </div>
            <div class="chat-footer bg-light">
                <i class="fa fa-paperclip" style="margin-right: 10px;"></i>
                <input v-model="newMessage" type="text" class="form-control" placeholder="Escribe tu mensaje..."
                    @keyup.enter="sendMessage" style="margin-right: 10px;">
                <i class="fa fa-whatsapp" @click="sendMessage"></i>
            </div>
        </div>
    </div>
</template>
  
<script>
/*eslint-disable */


export default {
    data() {
        return {
            showMenu: false,
            newMessage: "",
            messageIdCounter: 1, // Inicializar messageIdCounter
            messages: {
                'User 1': [],
                'User 2': [],
                'User 3': []
            },
            contacts: [
                { name: 'User 1' },
                { name: 'User 2' },
                { name: 'User 3' }
            ],
            selectedUser: 'User 1',
            filteredMessages: []
        };
    },
    methods: {
        selectContact(userName) {
            this.selectedUser = userName;
            this.updateFilteredMessages(); // Actualizar mensajes filtrados según el usuario seleccionado
        },
        sendMessage() {
            if (this.newMessage.trim() !== "") {
                this.messages[this.selectedUser].push({
                    id: this.messageIdCounter++,
                    text: this.newMessage,
                    sender: 'user'
                });
                this.newMessage = "";
                this.updateFilteredMessages();

                this.$nextTick(() => {
                    const chatContent = this.$refs.chatContent;
                    chatContent.scrollTop = chatContent.scrollHeight;
                });
            }
        },
        updateFilteredMessages() {
            this.filteredMessages = this.messages[this.selectedUser];
        },

        



    },
    mounted() {


    }
};
</script>
  
<style>
/* Estilo para los mensajes */
.chat-message {
    font-size: 14px;
    margin: 5px;
    padding: 8px 12px;
    border-radius: 10px;
    display: inline-block;
    /*Se adapta al largo del contenido */
    word-wrap: break-word;
    /* Rompe las palabras largas si es necesario */
}

.derecha{
    text-align: right;
}

.izquierda{
    text-align: left;
}


.user-message {
    background-color: #DCF8C6;
    align-self: flex-start;
}

.contact-message {
    background-color: #E2E2E2;
    margin-left: auto;
    /* This will align contact messages to the right */
    border-radius: 10px;
    padding: 8px 12px;
    word-wrap: break-word;
    margin: 5px;
    font-size: 14px;
}

/* fin */

.chat-content {
    flex: 1;
    overflow-y: auto;
    padding: 15px;
}


body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
    height: 100vh;
}

.container-fluid {
    display: flex;
    height: 100%;
}

.chat-list {
    flex: 0 0 25%;
    border-right: 1px solid #d1d1d1;
    overflow-y: auto;
}

.chat-window {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    margin: 20px;
}

.chat-header {
    padding: 15px;
    border-bottom: 1px solid #d1d1d1;
}

.chat-content {
    flex: 1;
    overflow-y: auto;
    padding: 15px;
}

.chat-footer {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #d1d1d1;
}




</style>
  