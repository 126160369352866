/* eslint-disable */
import { JSEncrypt } from 'jsencrypt'
import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard/Dashboard.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import SendMessage from "../views/envios-masivos/SendMessage.vue"

import perfil from "../views/Perfil.vue";
import NotFound from "../views/404.vue";
import keys from '@/store/keys';


import Terminos from "../views/Terminos.vue";
import Referidos from "../views/Referidos/Index.vue";
import Mas from "../views/Mas.vue";
import Password from "../views/Password.vue";

import DasboardDev from "../views/Api-Developers/DashboardDev.vue";
import ApiEnvios from "../views/Api-Developers/ApiEnvios.vue";
import ApiConsultas from "../views/Api-Developers/ApiConsultas.vue";
import getFormulario from "../views/formulario-datos/registro.vue";
import getAutorizado from "../views/formulario-datos/NoAutorizado.vue";
import registroDatos from "../views/formulario-datos/ReporteMineriaDatos.vue";

import pruebas from "../views/pruebas.vue";
import chat from "../views/chat/chat.vue";

import Administracion from "../views/Administracion/Index.vue";



const routes = [
  {
    path: "/",
    name: "login",
    redirect: "/login",
    meta: { layout: 'full' }
  },
  {
    path: "/dashboard/referidos",
    name: "referidos",
    component: Referidos,
    meta: { requiresAuth: true, layout: 'horizontal', breadCrumb: "Mis referidos" }
  },
  {
    path: "/dashboard/mas",
    name: "mas",
    component: Mas,
    meta: { requiresAuth: true, layout: 'horizontal', breadCrumb: "Otras opciones" }
  },
  {
    path: "/dashboard/terminos",
    name: "terminos",
    component: Terminos,
    meta: { requiresAuth: true, layout: 'horizontal', breadCrumb: "Términos y condiciones" }
  },
  {
    path: "/dashboard/pruebas",
    name: "pruebas",
    component: pruebas,
    meta: { requiresAuth: true, layout: 'horizontal', breadCrumb: "Pruebas Socket" }
  },
  {
    path: "/dashboard/chat",
    name: "chat",
    component: chat,
    meta: { requiresAuth: true, layout: 'horizontal', breadCrumb: "Chat Pruebas" }
  },
  {
    path: "/dashboard/envios",
    name: "SendMessage",
    component: SendMessage,
    meta: {
      requiresAuth: true,
      rol: false,
      layout: 'horizontal',
      breadCrumb: "Envío de mensajes",
      requiredRole: ['Administrador', 'Secretaria', 'Normal'] //esto si funciona....
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true, layout: 'horizontal', breadCrumb: "Inicio" }
  },
    {
    path: "/dashboard/registroDatos",
    name: "registroDatos",
    component: registroDatos,
    meta: { requiresAuth: true, layout: 'horizontal', breadCrumb: "Minería de datos" }
  },
  {
    path: "/administracion",
    name: "Administracion",
    component: Administracion,
    meta: { requiresAuth: true, layout: 'horizontal', breadCrumb: "Administración" }
  },
  {
    path: "/login",
    name: "Signin",
    component: Signin,
    meta: { layout: 'full' }
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: { layout: 'full' }
  },
  {
    path: "/dashboard/editarPerfil",
    name: "editarPerfil",
    component: perfil,
    meta: { requiresAuth: true, layout: 'full' }
  },
  {
    path: "/dashboard/password",
    name: "password",
    component: Password,
    meta: { requiresAuth: true, layout: 'full' }
  },
  {
    path: "/developers",
    name: "developers",
    component: DasboardDev,
    meta: { requiresAuth: false, layout: 'full' }
  },
  {
    path: "/developers/api/envios",
    name: "enviosapi",
    component: ApiEnvios,
    meta: { requiresAuth: false, layout: 'full' }
  },
  {
    path: "/developers/api/consultas",
    name: "consultasapi",
    component: ApiConsultas,
    meta: { requiresAuth: false, layout: 'full' }
  },
  {
    path: "/registro/:promoCode?",
    name: "registro",
    component: getFormulario,
    meta: { requiresAuth: false, layout: 'full' }
  },
  {
    path: '/registro/autorizacion',
    name: 'NotAutorizado',
    component: getAutorizado,
    
    meta: { requiresAuth: false, layout: 'full' }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: { layout: 'full' }
  }


];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});





router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    const userData = localStorage.getItem("userData");
    const user = userData;
    
    if (!user) {
      next('/login');
    } else {
      // Verificar si la ruta requiere un rol específico
      const requiredRole = to.meta.requiredRole;
      if (requiredRole) {
        const session = localStorage.getItem('session');
        const decrypt = new JSEncrypt();
        decrypt.setPrivateKey(keys.publicKeyBackoffice);
        const userRole = decrypt.decrypt(session);

        /* console.log('userRole: ' + userRole);
        console.log('requiredRole: ' + requiredRole); */
        
        if (Array.isArray(requiredRole) && requiredRole.includes(userRole)) {
          next();
        } else {
          // Si el usuario no tiene el rol requerido, redirige a la página de inicio o donde desees.
          next('/dashboard'); // O cualquier otra ruta que desees.
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});






router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;