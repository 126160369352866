<!-- eslint-disable vue/comment-directive -->
<template>
    <div class="container-fluid">
        <div class="row" style="width: 100%;margin:0px">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px">
                <nav class="navbar navbar-expand-lg navbar-dark bg-dark"
                    style="height: 250px;display:block;    margin: 0px !important">

                    <div style=" justify-content: space-between;display: flex;">

                        <router-link to="/developers">
                            <img class="imagen img-fluid"
                                src="https://www.whatsivo.com/wp-content/uploads/2021/06/logo-whatsivoRecurso-1.png"
                                alt="Whatsivo Logo" />
                        </router-link>

                    </div>


                    <div class="mt-3" lg="5">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-12 col-md-6 col-lg-6">
                                    <div class="card text-center shadow">
                                        <div class="card-header">
                                            <a class="d-flex align-items-center justify-content-center m-top2">
                                                API REST - MENSAJERÍA DE WHATSAPP
                                                <i class="fab fa-whatsapp ms-2"></i>
                                            </a>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <h5 class="mb-0">
                                                        API:
                                                        <span style="font-style: italic; font-weight: bold"
                                                            class="precio">NO OFICIAL
                                                        </span>
                                                    </h5>
                                                    <hr />
                                                </div>
                                            </div>
                                            <form autocomplete="off" @submit.prevent="enviaSms">
                                                <div class="row justify-content-center">
                                                    <div class="col-12 col-md-12 col-lg-6 mt-3">
                                                        <label class="form-label" style="text-align: left;">TIPO DE
                                                            MENSAJE</label>
                                                        <select class="form-select" required v-model="tipoMensaje"
                                                            v-on:change="bloquearInput">
                                                            <option value="">Elegir</option>
                                                            <option>TEXTO</option>
                                                            <option>IMAGEN</option>
                                                            <option>DOCUMENTO</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-6 mt-3">
                                                        <label class="form-label">Archivo</label>
                                                        <input @change="cargarImagen" type="file" id="txtRutaImagen"
                                                            required name="txtRutaImagen" accept=".jpeg, .jpg, .png, .pdf"
                                                            class="form-control" :disabled="inputBloqueado" />
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center">
                                                    <div class="col-12 col-md-12 col-lg-12 mt-3">



                                                        <MazPhoneNumberInput type="tel" default-country-code="EC"
                                                            style="width: 100%;" no-flags no-example required
                                                            show-code-on-list size="md" v-model="phoneNumber"
                                                            @update="results = $event" :translations="{
                                                                countrySelector: {
                                                                    placeholder: 'Código',
                                                                    error: 'Elija un pais',
                                                                },
                                                                phoneInput: {
                                                                    placeholder: 'Nro. celular',
                                                                    example: 'Ejemplo:',
                                                                },
                                                            }" />

                                                    </div>
                                                </div>
                                                <div class="row justify-content-center">
                                                    <div class="col-12 col-md-12 col-lg-12 mt-3">
                                                        <textarea placeholder="Escribe aqui tu mensaje ..." rows="8"
                                                            wrap="soft" class="form-control" v-model="txtMessage"
                                                            v-bind:class="{ 'is-invalid': txtMessage.length > 200 }"
                                                            :maxlength="200" required @paste="onPaste"></textarea>
                                                        <small v-if="txtMessage.length <= 200">Caracteres restantes: {{ 200
                                                            -
                                                            txtMessage.length }}</small>
                                                        <small v-else style="color: red;">Has superado el límite de
                                                            caracteres.</small>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center">


                                                    <div class="col-12 col-md-12 col-lg-12 mt-3">
                                                        <p> {{ rsptJson }} </p>
                                                    </div>



                                                    <div class="col-12 col-md-12 col-lg-12 mt-1">
                                                        <button type="submit" class="btn btn-success btn-lg btn-block">
                                                            Enviar Mensaje <i class="fab fa-whatsapp ms-2"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>







                </nav>

            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */


export default {
    name: "dashboardDev-default",
    components: {

    },
    data() {
        return {
            isActive: false,
            phoneNumber: null,
            countryCode: '',
            results: null,
            txtMessage: '',
            inputBloqueado: true,
            tipoMensaje: '',
            rsptJson: '',
            rutaImagen: ''

        }
    },

    watch: {
        results(newValue) {
            if (newValue.isValid) {
                this.isActive = true
                this.phoneNumber = newValue.e164
                this.countryCode = newValue.countryCode

            } else {
                this.isActive = false
                this.countryCode = ''
                this.code = ''

            }
        },
    },

    methods: {
        showSuccessToast(message) {
            this.$toast.success(message, {
                position: "bottom-right",
                max: 10
            });
        },

        showErrorToast(message) {
            this.$toast.error(message, {
                position: "bottom-right",
                max: 10
            });
        },

        bloquearInput(event) {

            if (event.target.value === "TEXTO" || event.target.value === "") {
                this.inputBloqueado = true;
            } else {
                this.inputBloqueado = false;
            }

        },


        onPaste(event) {
            const clipboardData = event.clipboardData || window.clipboardData;
            const pastedText = clipboardData.getData('text');
            const shortenedText = pastedText.slice(0, 200);
            document.execCommand('insertText', false, shortenedText);
            event.preventDefault();
        },

        enviaSms(event) {
            event.preventDefault();

            switch (this.tipoMensaje) {
                case 'TEXTO':
                    this.sendTexto()
                    break
                case 'IMAGEN':
                    this.sendImagen()
                    break
                case 'DOCUMENTO':
                    this.sendImagen()
                    break
                default:
                    this.showErrorToast('Tipo de mensaje no válido')
            }

            if (!this.isActive) {
                this.showErrorToast('El móvil no es correcto')
            }




        },

        async sendTexto() {

            let enviaDatos = {
                'sender': 'administrador',
                'number': this.phoneNumber,
                'message': this.txtMessage + `\n\n---------------------------------------------------
----- MENSAJE DE PRUEBAS -----
---------------------------------------------------
Un desarrollo de *WhatSivo*.
Servicio de mensajería por WhatsApp.
API REST para desarrolladores.
---------------------------------------------------
Visítanos www.whatsivo.com
---------------------------------------------------
Escríbenos a nuestro WhatsApp
https://wa.me/593985067512
Mayor información.`
            }


            const { data }  = await this.$https.post('api/auth/sendMessage', enviaDatos);

            console.log(data.code);

            let respuestas = {
                true: {
                    Code: 200,
                    Status: 'Enviado',
                    Type: 'Mensaje de Texto'
                },
                false: {
                    Code: 210,
                    Status: 'La línea no se encuentra registrada en WhatsApp',
                    Type: 'Mensaje de Texto'
                }
            };

            if (data.status in respuestas && data.code === 200) {
                this.rsptJson = respuestas[data.status];

                return this.showSuccessToast('Mensaje enviado')

            } else {

                return this.showErrorToast('El servicio no se encuentra no disponible')

            }



        },

        async sendImagen() {

            let message = this.txtMessage + `\n\n---------------------------------------------------
----- MENSAJE DE PRUEBAS -----
---------------------------------------------------
Un desarrollo de *WhatSivo*.
Servicio de mensajería por WhatsApp.
API REST para desarrolladores.
---------------------------------------------------
Visítanos www.whatsivo.com
---------------------------------------------------
Escríbenos a nuestro WhatsApp
https://wa.me/593985067512
Mayor información.`

            let data = new FormData();
            let file = this.rutaImagen;
            data.append("sender", 'administrador');
            data.append("number", this.phoneNumber);
            data.append("caption", message);
            data.append("tipoMessage", this.tipoMensaje);
            data.append("file", file);
            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };

            const data2 = await this.$https.post('api/auth/sendMedia', data, config);

            let respuestas = {
                true: {
                    Code: 200,
                    Status: 'Enviado',
                    Type: this.tipoMensaje
                },
                false: {
                    Code: 210,
                    Status: 'La línea no se encuentra registrada en WhatsApp',
                    Type: this.tipoMensaje
                }
            };

            if (data2.data.status in respuestas) {
                this.rsptJson = respuestas[data2.data.status];

                return this.showSuccessToast('Mensaje enviado')

            } else {

                return this.showErrorToast('El servicio no se encuentra no disponible')

            }



        },

        cargarImagen(ev) {
            const file = ev.target.files[0];
            const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            const maxFileSize = 2000000;

            if (!file) {
                return;
            }

            if (file.size > maxFileSize) {
                this.showErrorToast('El tamaño máximo del archivo es de 2MB.');
                return this.resetInputFile();
            }

            if (this.tipoMensaje === 'IMAGEN' && !allowedExtensions.exec(file.name)) {
                this.showErrorToast('Solo se permiten archivos en formato JPG, JPEG o PNG.');
                return this.resetInputFile();
            }

            if (this.tipoMensaje === 'DOCUMENTO' && file.type.includes('video')) {
                this.showErrorToast('No se permiten videos.');
                return this.resetInputFile();
            }

            this.rutaImagen = file;
        },

        resetInputFile() {
            document.getElementById('txtRutaImagen').value = null;
        },










    },

    mounted() {

    }
}
</script>

<style scoped>
.container-fluid {
    padding: 0px;
    margin: 0px;
}

.bg-dark {
    background: linear-gradient(87deg, #f7931e, #f7931e) !important;
    height: 160px!important;
}


.card-header {
    background-color: #f0f2f5;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Para centrar horizontalmente el contenido */
}


.imagen {
    width: 200px;
    /* Adjust the width as per your requirements */
    height: auto;
    /* Maintain aspect ratio */
}


@media screen and (max-width: 769px) {
    .m-top {
        margin-top: 2rem;
    }

    .m-top2 {
        font-size: 14px;
    }

    .imagen {
        width: 30%;
        height: auto;
        /* Maintain aspect ratio */
    }

    .bg-dark {
        height: 140px!important;
    }    

}
</style>