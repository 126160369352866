<template>
    <div class="card" style="margin-bottom:10px">

        <div class=" card-body">

            <div class="row">

                <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12" style="margin-bottom:10px;  ">

                    <h5 class="card-title mb-0 text-uppercase font-weight-bold text-center"
                        style="color: black; font-size: 12px;"> Nube de Datos
                    </h5>

                </div>

            </div>

            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-12 col-sm-12"
                    style="margin-top:10px; display:flex; justify-content:center;">

                    <Skeletor v-if="loading" :shimmer="true" height="38" width="100%" />

                    <div v-else class="input-group justify-content-center">
                        <input type="text" required v-model="link3" :readonly="true"
                            class="form-control col-lg-6 text-center" id="link3" ref="link3"
                            aria-describedby="button-addon2" style="border-color: initial!important; ">
                        <button class="btn-outline-secondary btn2" @click="copyInputValue('link3')"
                            style=" background: #00b63e !important; ">
                            <i class="fa fa-clone" aria-hidden="true" style="margin-right: 5px;"></i>
                        </button>
                    </div>


                </div>
            </div>



        </div>
    </div>
</template>
<script>

export default {
    components: {



    },
    props: {
        prueba: { type: String, required: true }
    },
    data() {


        return {
            loading: true,
            link3: '',
        }
    },
    computed: {

    },
    watch: {

    },

    activated() {
        //console.log(this.prueba.slice(0, -3))
    },
    mounted() {

        setInterval(() => {
            this.loading = false;
        }, 3000);

        this.link3 = window.location.host + '/registro/' + this.prueba;


    },
    methods: {

        copyInputValue(model) {


            const inputElement = document.getElementById(model);
            inputElement.select();
            document.execCommand('copy');
            inputElement.setSelectionRange(0, 0);
            this.$toast.success("Link Copiado exitosamente", {
                position: "top-right",
                max: 10
            })


        }



    },
}
</script>
        
<style scope></style>