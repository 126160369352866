<!-- eslint-disable vue/comment-directive -->
<template>
    <vue-final-modal v-model="showModal" :esc-to-close="true" @closed="closed" classes="modal-container"
        content-class="modal-content">

        <span class="modal__title">Se enviará un código a su celular</span>
        <div class="modal__content">

            <div class="row">

                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                    <MazPhoneNumberInput type="tel" default-country-code="EC" style="width: 100%;" no-flags no-example
                        required autocomplete="off" show-code-on-list size="sm" v-model="phoneNumber"
                        @update="results = $event" :translations="{
                            countrySelector: {
                                placeholder: 'Código',
                                error: 'Elija un pais',
                            },
                            phoneInput: {
                                placeholder: 'Nro. celular',
                                example: 'Ejemplo:',
                            },
                        }" />

                </div>


                <div class="row">
                    <div class="col-10">
                        <!-- Contenido de la columna -->
                    </div>
                    <div class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1" align="center"
                        style="cursor:pointer; display:flex; align-items: center;" @click="SendCodeWs">
                        <span style="margin:2px" class="enviarClass">Enviar</span>
                        <i style="cursor:pointer;" class=" enviarClass fab fa-whatsapp text-sm "></i>
                    </div>
                </div>

            </div>
        </div>


        <span class="modal__title">Ingrese el código enviado a su celular</span>
        <div class="modal__content">

            <div class="row">

                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                    <pin-input ref="code" class="wrapper" v-model="code" :secure="false" :characterPreview="true"
                        :charPreviewDuration="500" :length="6" input-class="pinInput"
                        @completed="handleOnComplete($event)" />

                </div>


                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                    <hr class="horizontal dark" />

                    <button class="btn btn-primary btn-sm" style="width: 100%;" @click="validaCode" :disabled="isDisabled">
                        Validar Código
                    </button>
                </div>
            </div>

        </div>
    </vue-final-modal>



    <div class="container-fluid">
        <div class="row" style="width: 100%;margin:0px">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding:0px">
                <nav class="navbar navbar-expand-lg navbar-dark bg-dark"
                    style="height: 250px;display:block;    margin: 0px !important">

                    <div style=" justify-content: space-between;display: flex;">

                        <router-link to="/developers">
                            <img class="imagen img-fluid"
                                src="https://www.whatsivo.com/wp-content/uploads/2021/06/logo-whatsivoRecurso-1.png"
                                alt="Whatsivo Logo" />
                        </router-link>

                    </div>



                    <div class="mt-5" lg="4">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-12 col-md-12 col-lg-9">
                                    <div class="card text-center shadow">
                                        <div class="card-header">
                                            <h4 class="m-top2">API REST DEVELOPERS CÉDULA - RUC</h4>
                                        </div>
                                        <div class="card-body">
                                            <div class="col-12">
                                                <h3 class="txt_solo m-top2">
                                                    Country:
                                                    <span style="font-style: italic; font-weight: bold">Ecuador
                                                    </span>
                                                </h3>
                                                <hr />
                                            </div>
                                            <form autocomplete="off" @submit.prevent="consultar">
                                                <div class="row justify-content-center">
                                                    <div class="col-12 col-md-6 col-lg-4 mt-3">
                                                        <label class="form-label">SELECCIONAR</label>
                                                        <select class="form-select" required v-on:change="tipoConsulta">
                                                            <option value="">Elegir</option>
                                                            <option>Dni - Cédula</option>
                                                            <option>Ruc</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4 mt-3">
                                                        <label class="form-label">DNI / CÉDULA / RUC</label>
                                                        <input required type="tel" @keypress="NumbersOnly" ref="myDni"
                                                            v-model="txtIdentidad" class="form-control"
                                                            placeholder="IDENTIDAD" />


                                                    </div>
                                                </div>
                                                <div class="row justify-content-center mt-4">
                                                    <div class="col-12 col-md-12 col-lg-12">
                                                        <button type="submit"
                                                            class="btn btn-primary btn-lg btn-block">Consultar
                                                            Ahora!</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5" lg="4" v-show="ocultar">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-12 col-md-12 col-lg-9">
                                    <div class="card text-center shadow">
                                        <div class="card-body">
                                            <div class="col-12">
                                                <hr />
                                            </div>
                                            <form>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 col-lg-12 mt-3">
                                                        <textarea class="form-control" rows="50" :disabled="resp"
                                                            style="width: 100%" v-model="respuesta"></textarea>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>







                </nav>

            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';

export default {
    name: "dashboardDev2-default",
    components: {

    },
    data() {
        return {
            selectControl: '',
            txtIdentidad: '',
            respuesta: '',
            resp: true,
            ocultar: false,
            isDisabled: true,
            showModal: false,
            code: '',
            phoneNumber: null,
            countryCode: '',
            results: null,
            isActive: null,
            myIp: null
        }
    },

    watch: {
        results(newValue) {
            if (newValue.isValid) {
                this.isActive = true
                this.phoneNumber = newValue.e164
                this.countryCode = newValue.countryCode

            } else {
                this.isActive = false
                this.countryCode = ''
                this.code = ''

            }
        },
    },

    methods: {
        NumbersOnly(event) {
            const charCode = event.which ? event.which : event.keyCode;
            if ((charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) || event.key === ".") {
                event.preventDefault();
            }
        },
        resetForm() {
            this.phoneNumber = null;
            this.code = null;

        },
        showSuccessToast(message) {
            this.$toast.success(message, {
                position: "bottom-right",
                max: 10
            });
        },

        showErrorToast(message) {
            this.$toast.error(message, {
                position: "top-right",
                max: 10
            });
        },

        getTimer() {

            this.$swal.fire({
                html: "<span style='font-size:14px'>Verificando, por favor espere.. </span>",
                position: "center",
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                    this.$swal.showLoading();
                }
            })


        },

        getExitoso() {

            this.$swal({
                title: "Consulta éxitosa",
                icon: 'success',
                width: '400px',
                showCancelButton: false,
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
                timer: 1500, // tiempo en milisegundos (en este ejemplo, un segundo)
                timerProgressBar: true, // mostrar barra de progreso del temporizador
                allowOutsideClick: () => !this.$swal.isLoading(),
            }).then((result) => {
                // Este código se ejecutará después de que se cierre la ventana (en este caso, un segundo después)
                if (result.dismiss === this.$swal.DismissReason.timer) {
                    // La ventana se cerró automáticamente después del temporizador
                    //console.log('Mensajes enviados');
                }
            });

        },

        getError(message) {

            this.$swal({
                title: "¡Hubo un error!",
                text: message,
                icon: "error",
                width: "400px",
                showCancelButton: false,
                confirmButtonText: "Aceptar",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    //Esta propiedad te permite definir una función que se ejecuta alguna acción antes de confirmar, si es necesario
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            });

        },

        getSuccess(mensaje) {
            this.$swal({
                title: "¡Éxito!",
                text: mensaje,
                icon: "success",
                width: "400px",
                showCancelButton: false,
                confirmButtonText: "Aceptar",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    //Esta propiedad te permite definir una función que se ejecuta alguna acción antes de confirmar, si es necesario
                },
                allowOutsideClick: () => !this.$swal.isLoading(),
            });
        },

        tipoConsulta(ev) {

            if (ev.target.value == 'Dni - Cédula') {
                this.selectControl = 'cedula'
                this.txtIdentidad = ''
                this.ocultar = false
            }

            if (ev.target.value == 'Ruc') {
                this.selectControl = 'ruc'
                this.txtIdentidad = ''
                this.ocultar = false
            }

            if (ev.target.value == '') {
                this.selectControl = ''
                this.txtIdentidad = ''
                this.ocultar = false
            }


        },

        consultar(event) {
            event.preventDefault();

            this.ocultar = false

            this.getDatosRuc()



        },

        async getCedula(codigo) {
            this.getTimer()

            let enviaDatos = {
                dni: this.txtIdentidad,
                autorizacion: 'pvea1992'
            }

            const data = await this.$https.post('api/auth/getSearchMineriaDatos', enviaDatos);
            console.log(data.data.code);

            if (data.data.code == 200) {
                let nombre = (data.data.datos.nombres);

                console.log('Nombre: ' + nombre);

                const respuesta = this.$https.post('/api/auth/updateNombres', { code: codigo, dni: this.txtIdentidad, nombre: nombre })
                this.ocultar = true
                this.respuesta = JSON.stringify(data.data.datos, null, 2);
                this.getExitoso()

            } else if (data.data.code == 206) {
                this.ocultar = false
                this.getError('La cédula es incorrecta')
            } else if (data.data.code == 208) {
                this.ocultar = false
                this.getError('Error de consulta, vuelva a intentarlo')
            }


        },

        getDatosRuc() {

            let cedula = '';
            const identidad = String(this.txtIdentidad);

            if (this.selectControl === 'ruc') {
                if (identidad.length === 12 || identidad.length === 13) {

                    if (identidad.length === 12) {
                        cedula = '0' + identidad;
                        this.txtIdentidad = cedula;
                    }

                    this.getRuc();

                } else {
                    this.showErrorToast('Verifique el Ruc');
                }
            }

            if (this.selectControl === 'cedula') {
                if (identidad.length === 9 || identidad.length === 10) {

                    if (identidad.length === 9) {
                        cedula = '0' + identidad;
                        this.txtIdentidad = cedula;
                    }

                    this.showModal = true;

                } else {
                    this.showErrorToast('Verifique la cédula');
                }
            }
        },

        async getRuc() {

            this.getTimer()

            try {

                const data = await this.$https_ws.post('api/v1/consultarRuc/', { ruc: this.txtIdentidad });

                if (data.data.code == 200) {
                    this.ocultar = true
                    this.respuesta = JSON.stringify(data.data.consulta, null, 2);
                    this.getExitoso()
                    return
                }

                if (data.data.code == 208) {
                    this.ocultar = false
                    this.getError('No existe el ruc: ' + this.txtIdentidad)
                    return
                } else {
                    return this.getError('Ha ocurrido un error')
                }

            } catch (error) {
                return this.getError(error.message)
            }

        },

        onSubmitPassword() {
            //console.log('Enviado desde el model');
        },

        togglePasswordNew() {
            //console.log('Enviado desde contraseña');
        },

        handleOnComplete() {
            //console.log('Codigo ingresado');
            this.isDisabled = false
        },

        async SendCodeWs() {

            if (!this.isActive) {
                return this.showErrorToast('El móvil no es correcto')
            }

            this.$swal({
                title: "¿Se enviará el código a su WhatsApp?",
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cancelar',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-secondary ml-1',
                },
                preConfirm: () => this.SendCodeWs2(),
            });
        },

        async SendCodeWs2() {

            if (!this.isActive) {
                return this.showErrorToast('El móvil no es correcto')
            }

            this.getTimer()

            const randomNum = Math.floor(100000 + Math.random() * 900000);

            const data = await axios.post('https://www.app-whatsivo.com:3000/api/auth/detallesPeticion');

            /* VERIFICO ANTES DE ENVIAR EL MENSAJE*/

            this.myIp = data.data.IP

            const respuesta = await this.$https.post('/api/auth/savePeticiones', { ip: data.data.IP, movil: this.phoneNumber, dni: this.txtIdentidad, code: randomNum })
            console.log(respuesta);

            if (respuesta.data.code == 204) {
                return this.getError(respuesta.data.message)
            }

            if (respuesta.data.code == 200) {
                this.getSuccess('Ingresa el código enviado')
            }


            /****************************/

            let rspt = data.data
            let detalleSesion = `*..::Detalle de Solicitud::..*\n*IP:* ${rspt.IP}\n*Dispositivo:* ${rspt.resul.nameDevice}\n*Sistema operativo:* ${rspt.resul.os} ${rspt.resul.versionOs}\n*Navegador*: ${rspt.resul.browser}\n`;

            let message = `..::Consulta de Datos DNI::..\n
*Cédula a consultar:* ${this.txtIdentidad}\n
-----CODE VERIFICATION-----
-------------- *${randomNum}* ---------------
-------------------------------------------\n
${detalleSesion}
-------------------------------------------
Un desarrollo de *WhatSivo*.
API REST para desarrolladores.
---------------------------------------------------
Escríbenos a nuestro WhatsApp
https://wa.me/593985067512
Mayor información.`


            let enviaDatos = {
                "sender": "administrador",
                "number": this.phoneNumber,
                "message": message
            }


            let enviaMensaje = await this.enviaCodeWs(enviaDatos)


            if (!enviaMensaje) {
                return this.getError('La línea no cuenta con WhatsApp')
            }

            if (enviaMensaje == 404) {
                return this.getError('Error inesperado')
            }




        },

        async enviaCodeWs(enviaDatos) {
            return new Promise((resolve, reject) => {
                this.$https_ws.post('/api/auth/sendMessage', enviaDatos)
                    .then(response => {
                        if (response.data.status) {
                            resolve(response.data.status);
                        }
                    })
                    .catch(e => {
                        if (!e.response.data.status) {
                            resolve(false);
                        } else {
                            resolve(404);
                        }
                    });
            });
        },

        async validaCode() {

            if (Number(this.code.length) != 6) {
                return this.showErrorToast('El código debe tener 6 dígitos');
            }


            let codigo = this.code

            const respuesta = await this.$https.post('/api/auth/updatePeticiones', { ip: this.myIp, code: this.code, dni: this.txtIdentidad })

            if (respuesta.data.code == 200) {

                this.resetForm()
                this.showModal = false
                this.getCedula(codigo)
            }

            if (respuesta.data.code == 204) {
                this.getError(respuesta.data.message)
            }

            if (respuesta.data.code == 404) {
                this.getError(respuesta.data.message)
            }

            if (respuesta.data.code == 208) {
                this.getError(respuesta.data.message)
            }



        }



    },

    mounted() {
        this.$refs.myDni.focus()
    }
}
</script>

<style scoped>
.container-fluid {
    padding: 0px;
    margin: 0px;
}

.bg-dark {
    background: linear-gradient(87deg, #f7931e, #f7931e) !important;
    height: 160px;
}


.card-header {
    background-color: #f0f2f5;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Para centrar horizontalmente el contenido */
}



.centrar {
    justify-content: center;
}

.imagen {
    width: 200px;
    /* Adjust the width as per your requirements */
    height: auto;
    /* Maintain aspect ratio */
}

.btn-primary:hover {
    color: #fff !important;
    background-color: #f7931e !important;
    border-color: #f7931e !important;
}


.btn-secondary {
    color: #fff !important;
    background-color: #3f3e3e !important;
    border-color: #3f3e3e !important;
}

.etiquetas {
    float: Left;
}

.enviarClass {
    margin: 5px;
    color: #22c300;
    font-size: 14px;
    font-weight: 500px;
}


.bg-dark {
    height: 160px !important;
}


@media screen and (max-width: 769px) {
    .m-top {
        margin-top: 2rem;
    }

    .m-top2 {
        font-size: 16px;
    }

    .imagen {
        width: 30%;
        height: auto;
        /* Maintain aspect ratio */
    }

    .bg-dark {
        height: 140px !important;
    }

}
</style>